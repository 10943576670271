import * as React from "react";
import Headings from "../../Generic/Heading";
import Paragraph from "../../Generic/Paragraph";
import { useEffect } from "react";

interface Props {
    content?: any
}

/**
 * SuccessBlock Component
 * @param content - Content from the CMS.
 */

const SuccessBlock = ({
  content,
}: Props) => {

  useEffect(() => {
    const targetElement = document.getElementById('formSuccessBlock')
    if (targetElement && targetElement !== null) {
      const top = targetElement?.offsetTop - 100
      window.scrollTo({top: top, behavior: 'smooth'})
    }
  })
  
  return (
    <>
      <div className={`sectionBottomSpacing`} id="formSuccessBlock">
        <Headings content={content?.title} variant="H2" />
        <Paragraph content={content?.description} />
      </div>
    </>
  );
};

export default SuccessBlock;
