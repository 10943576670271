import * as React from "react"
import Paragraph from "../../Generic/Paragraph"
import TextButton from "../../Generic/Button/Text/Button"
import DisclaimerBlock from "../Disclaimer"
import Headings from "../../Generic/Heading"
import Image from "../../Generic/Image"

interface Props {
    content?: any
}

/**
 * Footer COmponent
 * @param content - Content from the CMS.
 */
const Footer = ({content}: Props) => {
    let background = "purple"
    background = background?.toLowerCase()
    const bgColor = background === 'lightpurple' ? 'bg-purple-10' : background === 'orange' ? 'bg-orange-10' : background === 'teal' ? 'bg-teal-10' : background === 'purple' ? 'bg-purple-100' : 'bg-white'

    return (<>
        <footer role="contentinfo" data-testid="footerBlock" className={`layoutSpacing w-100 py-48 ${bgColor}`}>
            <div className="layout">
                <div className="flex mb-24" data-testid="footerIamge">
                    <a href="/" aria-label="Go to homepage">
                        <Image imageUrl={content?.fields?.footerLogo?.fields?.file?.url} altText={content?.footerLogo?.fields?.description} height="100" width="auto"/>
                    </a>
                </div>
                <div className="pt-48 border-t border-purple-pressedDark">
                    <div className="flex flex-col xl:flex-row">
                        <div className="flex flex-col w-full xl:w-50 xl:flex-row flex-1 gap-32 xl:gap-48">
                            <div>
                                <a href={content?.fields?.emergencyServicesMembersNumberwithspace[0]?.fields?.inputText} aria-label={content?.fields?.emergencyServicesMembersNumberwithspace[0]?.fields?.inputHeadline} className="underline text-white">
                                    <Headings variant="H3" content={content?.fields?.emergencyServicesMembersNumberwithspace[0]?.fields?.inputHeadline} classes="text-white pb-0 mb-8 w-max" />
                                </a>
                                <Paragraph content={content?.fields?.emergencyServicesMembers} classes="text-white" />
                            </div>
                            <div>
                                <a href={content?.fields?.stateSuperMembersNumberWithSpace[0]?.fields?.inputText} aria-label={content?.fields?.stateSuperMembersNumberWithSpace[0]?.fields?.inputHeadline} className="underline text-white">
                                    <Headings variant="H3" content={content?.fields?.stateSuperMembersNumberWithSpace[0]?.fields?.inputHeadline} classes="text-white pb-0 mb-8 w-max" />
                                </a>
                                <Paragraph content={content?.fields?.stateSuperMembers} classes="text-white" />
                            </div>
                        </div>
                        <div className="w-full xl:w-50 xl:columns-2 mt-32 xl:mt-0">
                            {content?.fields?.footerMenus?.fields?.menusDropdown?.map((item, index) =>
                                <div key={`${item?.sys?.id}-${index}`}>
                                    <TextButton name={item?.fields?.menuName} link={item?.fields?.menuLink} variant="dark" fontColor="text-white" classes="mb-8" underline={true} />
                                </div>
                            )}
                        </div>
                    </div>
                    <DisclaimerBlock fullWidth={true} content={content?.fields?.footerDescription} classes="text-white py-32 text-md w-100 xl:w-50 xl:pr-32" />
                    <div className="flex flex-wrap mt-24 gap-32">
                        {content?.fields?.media?.map((item) =>
                            <div key={item?.sys?.id} className="max-w-100 max-h-100">
                                <Image imageUrl={item?.fields?.file?.url} altText={item?.fields?.description} />
                            </div>
                        )}
                    </div>
                </div>
                <div className="flex flex-col xl:flex-row justify-between pt-48 mt-48 border-t border-purple-pressedDark">
                    <div className="flex flex-wrap md:gap-32">
                        {content?.fields?.footerMenuBottom[0]?.fields?.menusDropdown?.map((item, index) =>
                            <div key={`${item?.sys?.id}-${index}`}>
                                <TextButton name={item?.fields?.menuName} link={item?.fields?.menuLink} variant="dark" fontColor="text-white" classes="mb-24 xl:mb-0 underline" />
                            </div>
                        )}
                    </div>
                    <div className="flex gap-32">
                        {content?.fields?.socialMedia?.map((item) =>
                            <div key={item?.sys?.id}>
                                <a href={item?.fields?.socialMediaUrl} aria-label={`link-${item?.fields?.socialMediaPlatform}`} className="w-24 h-24" target="_blank" rel="noreferrer">
                                    <Image className="max-w-24 max-h-24" imageUrl={item?.fields?.socialMediaIcon?.fields?.file?.url} altText={item?.fields?.socialMediaPlatform} />
                                </a>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </footer>
        </> 
    )
}

export default Footer