import * as React from "react";
import Paragraph from "../Paragraph";

interface Props {
  imageUrl: string;
  altText: string;
  width?: string;
  height?: string;
  className?: string;
  caption?: string;
}

/**
 * Image Component
 * @param imageUrl - URL of the image.
 * @param altText - Alternate text of the image.
 * @param width (optional) - Width of the image.
 * @param height (optional) - Height of the image.
 * @param className (optional) - CSS Class of the image.
 */

const Image = ({ imageUrl, altText, width, height, className, caption }: Props) => {
  return (
    <>
      <img
        src={imageUrl}
        alt={altText ? altText : ""}
        width={`${width ? width : "auto"}`}
        height={`${height ? height : "auto"}`}
        role={!altText ? "presentation" : ""}
        className={`object-cover ${className}`}
      />
      {caption && <Paragraph content={caption} classes={`!text-base text-neutral-90 mb-16 ${caption ? 'mt-16' : ''}`} />}
    </>
  );
};

export default Image;
