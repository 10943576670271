import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import Headings from "../../Generic/Heading";
import InputComp from "../../Generic/Form/Input";
import PrimaryButton from "../../Generic/Button/Primary/Button";
import { ReactComponent as CloseIcon } from "../../../Assets/close-purple.svg";
import {
  InstantSearch,
  Hits,
  Configure,
  Pagination,
  RefinementList,
  useInstantSearch,
} from "react-instantsearch";
import { searchClient } from "../../../AlgoliaClient/Client";
import Listing from "../Listing";
import "./style.css";
// import DropdownComp from "../../Generic/Form/Dropdown";

interface hitProps {
  hit: {
    url: string;
    hostname: string;
    path: string;
    depth: number;
    title: string;
    headers: [];
    description: string;
    content: string;
    objectID: string;
  };
}

const SearchResults = () => {
  const [searchParam, setSearchParam] = useSearchParams();
  const [query, setQuery] = useState(searchParam.get("query") || null);
  const [searchValue, setSearchValue] = useState("");
  const [results, setResults] = useState([]);
  const [totalHits, setTotalHits] = useState(0);
  const [paginationPadding, setPaginationPadding] = useState(3);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 728) {
        setPaginationPadding(2);
      } else {
        setPaginationPadding(3);
      }
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const getQuery = searchParam.get("query");
    setQuery(getQuery || null);
    if (searchValue === "" && getQuery !== null) {
      setSearchValue(getQuery);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParam]);

  const handleSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setSearchParam({ query: searchValue });
  };

  const Hit = ({ hit }: hitProps) => {
    if (!hit?.title || !hit?.path) {
      return;
    } else {
      const content = {
        imageUrl: null,
        iconUrl: null,
        publishDate: null,
        tag: null,
        timeToRead: null,
        videoPodcastUrl: null,
        buzzsproutId: null,
        headLine: hit?.title ? hit?.title : null,
        contentDescription: hit?.description ? hit?.description : null,
        contentCtaLinkToPage: hit?.path ? hit?.path : null,
      };
      return <Listing content={content} />;
    }
  };

  const FallBackReturns = () => {
    const { results, status } = useInstantSearch();
    setTotalHits(results.nbHits);
    setResults(results.hits);
    if (status === "loading" || status === "stalled") {
      return (
        <div className="text-center pt-20 pb-14 text-2xl text-neutral-70">
          Loading...
        </div>
      );
    }

    if (results && results.hits.length === 0) {
      return (
        <div className="text-center pt-20 pb-14 text-2xl text-neutral-70">
          No Results Found.
        </div>
      );
    }
  };

  return (
    <>
      <InstantSearch
        searchClient={searchClient}
        indexName="uat_clawler_pages"
        // onStateChange={(uiState) => handleStateChanges(uiState)}
        data-testid="searchResults"
      >
        <div className="layoutSpacing pt-9 pb-5">
          <div className="layout">
            <Headings
              variant="h2"
              content="Search results"
              classes="text-purple-100"
            />
            <form onSubmit={handleSubmit}>
              <div className="md:flex justify-between">
                <div className="flex-1 md:mr-5 relative">
                  <InputComp
                    className="py-0 md:py-16"
                    aria-label="Search Input"
                    value={searchValue}
                    onChange={(name, value: string) => setSearchValue(value)}
                  />
                  {searchValue !== "" && (
                    <button
                      className="absolute right-16 top-32 md:top-32"
                      type="button"
                      onClick={() => setSearchValue("")}
                    >
                      <CloseIcon />
                    </button>
                  )}
                </div>
                <div className="hidden md:block md:py-16">
                  <PrimaryButton
                    name="Search"
                    variant="dark"
                    buttonType="submit"
                    disabled={searchValue.length < 3}
                  />
                </div>
              </div>
            </form>
          </div>
        </div>
        {query !== null && (
          <div className="w-100 border-neutral-50 border-t mb-2">
            <div className="layoutSpacing py-5">
              <div className="layout">
                <div className="border-neutral-50 border-b py-16">
                  {/* Filter */}
                  {/* <div className="md:flex md:items-center mb-6">
                  <div className="mb-3 font-semibold">Filter by</div>
                  <DropdownComp className="md:ml-5  font-semibold" />
                  <DropdownComp className="md:ml-6 md:mr-6 font-semibold" />
                  <div className="mb-3 font-semibold">
                    <button className="underline">Clear all</button>
                  </div>
                </div> */}
                  <p>
                    Showing {totalHits} results for &quot;{query}&quot;
                  </p>
                  <RefinementList attribute="category" />
                </div>
                {/* Results */}
                <FallBackReturns />
                <Configure query={query} hitsPerPage={10} />
                <Hits hitComponent={Hit} />
                {results && results.length !== 0 && (
                  <>
                    <Pagination
                      showFirst={false}
                      showLast={false}
                      padding={paginationPadding}
                      classNames={{
                        root: "mb-9",
                        list: "flex justify-center space-x-2 pagination bg-neutral-10 w-100 py-24",
                        item: "inline-flex  bg-neutral-10 text-neutral-100 border border-white text-md hover:bg-neutral-10 hover:border-purple-100 focus:bg-neutral-10 focus:border focus:border-purple-100 pagination",
                        link: "inline-flex flex-shrink-0 items-center justify-center w-48 h-48",
                        selectedItem: "active",
                        disabledItem:
                          "cursor-not-allowed text-neutral-50 hover:border-neutral-30",
                        previousPageItem:
                          "arrow inline-flex text-purple-100 flex-shrink-0 items-center justify-center bg-neutral-10 w-48 h-48 pagination hover:bg-neutral-10 hover:border hover:border-purple-100 focus:bg-neutral-10 focus:border focus:border-purple-100",
                        nextPageItem:
                          "arrow inline-flex text-purple-100 flex-shrink-0 items-center justify-center bg-neutral-10 w-48 h-48 pagination hover:bg-neutral-10 hover:border hover:border-purple-100 focus:bg-neutral-10 focus:border focus:border-purple-100",
                      }}
                    />
                  </>
                )}
              </div>
            </div>
          </div>
        )}
      </InstantSearch>
    </>
  );
};

export default SearchResults;
