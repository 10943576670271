import * as React from "react"
import { useCallback, useEffect, useState } from "react"
import InputComp from "../../Generic/Form/Input"
import TextAreaWithClose from "../../Generic/Form/Textarea"
import DropdownComp from "../../Generic/Form/Dropdown"
import PrimaryButton from "../../Generic/Button/Primary/Button"
import Paragraph from "../../Generic/Paragraph"
import { FormValidations } from "./validations"
import { UseEmailAPI, UseVerifyReCaptchaAPI, useContentAPI } from "../../../Hooks/useAPI"
import { TrackGAEvent } from "../../GoogleAnalytics"
import { useGoogleReCaptcha, GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'
import Selector from "../../Generic/Selector"
import SuccessBlock from "../SuccessBlock"
import Modal from "../Modal"

const enableReCaptcha = process.env.REACT_APP_ENABLE_RECAPTCHA

/**
 * Planned Retirement Dealings Form
 * 
 */

const PlannedRetirement = () => {
    const [chkbox, setChkbox] = useState(false)
    const [chkMsg, setChkMsg] = useState(false)
    
    const { data } = useContentAPI('contentModel', 'yourPlannedRetirementDateForm', "", false)

    const { executeRecaptcha } = useGoogleReCaptcha()
    const { validations } = FormValidations()
    const [invalidFocus, setInvalidFocus] = useState(false)
    const [validationData, setValidationData] = useState(validations)
    const [currentForm, setCurrentForm] = useState<any>()
    const [success, setSuccess] = useState(false)
    const [msg, setMsg] = useState("")
    const [start, setStart] = useState(false)

    const verifyReCaptcha = useCallback(async () => {
        if (!executeRecaptcha) {
            console.log('Recaptcha not available')
            return
        }
        const token = await executeRecaptcha('plannedRetirement')
        return token
    }, [executeRecaptcha])

    const debounce = (callback, wait) => {
        let timeoutId = null;
        return (...args) => {
          window.clearTimeout(timeoutId);
          timeoutId = window.setTimeout(() => {
            callback(...args)
          }, wait)
        }
      }

    const validateAimToRetire = (formValues) => {
        if (formValues["aimtoretire"] !== "") {
            const startDate = new Date()
            const endDate = new Date(formValues["aimtoretire"])
    
            if (endDate <= startDate) {
                validationData["aimtoretire"] = {
                    ...validationData["aimtoretire"],
                    error: true,
                    errorMsg: validationData["aimtoretire"].validationMsg
                }
                setValidationData({
                    ...validationData
                })
            }
        }
    }

    const validateDOB = (formValues) => {
        if (formValues["dob"] !== "") {
            const startDate = new Date()
            const endDate = new Date(formValues["dob"])

            if (endDate >= startDate) {
                validationData["dob"] = {
                    ...validationData["dob"],
                    error: true,
                    errorMsg: validationData["dob"]?.validationMsg
                }
                setValidationData({
                    ...validationData
                })
            }
        }
    }

    const validateFields = (name: string, value: string) => {
        const dataField = data?.data?.fields?.filter((item) => item?.id === name)
        let field = validationData[name]
        if (name === 'haveYouAlreadyRetired') {
             if (value === 'No') {
                validationData['aimtoretire'] = {
                    ...validationData['aimtoretire'],
                    required: true,
                    error: false
                }
                setValidationData({
                    ...validationData
                })
            } else {
                validationData['aimtoretire'] = {
                    ...validationData['aimtoretire  '],
                    required: false,
                    error: false
                }
                setValidationData({
                    ...validationData
                })
            }
        }
        if ((dataField[0]?.required || field?.required) && value === "") {
            validationData[name] = {
                ...validationData[name],
                error: true,
                errorMsg: validationData[name]?.requiredMsg
            }
            setValidationData({
                ...validationData
            })
        }
        if (value !== "") {
            validationData[name] = {
                ...validationData[name],
                error: false,
                errorMsg: ""
            }
            const regex = field.pattern ? field.pattern : ''
            if(regex && regex !== "") {
                const result = regex.test(value)
                if(!result) {
                    validationData[name] = {
                        ...validationData[name],
                        error: true,
                        errorMsg: validationData[name]?.validationMsg
                    }
                }
            }
            setValidationData({
                ...validationData
            })
        }
    }

    const submitForm = async (formData: any, form: any) => {
        let values = {}
        
        values["form"] = "planned-retirement"

        for(let d in formData) {
            const filteredValue = data?.data?.fields?.filter((item) => item.id === d)
            if (d === "aimtoretire" || d === "dob") {
                if (validations[d].format === 'date') {
                    if (formData[d] !== "") {
                        const formattedDate = new Date(formData[d]).toLocaleDateString('en-US')
                        values[filteredValue[0]?.name] = formattedDate.toString()
                    } else {
                        values[filteredValue[0]?.name] = ""
                    }
                }
            } else {
                if (filteredValue[0]?.name) {
                    values[filteredValue[0]?.name] = formData[d]
                } else {
                    values[validations[d]?.text] = formData[d] === 'true' ? 'Yes' : 'No'
                }
            }
        }

        if (enableReCaptcha === "0") {
            const response = await UseEmailAPI(values)
    
            if (response.status === 200) {
                setSuccess(true)
                setMsg(validationData['success'])
                TrackGAEvent('form_submit', 'Planned Retirement', window.location.pathname)
                form.reset()
            } else {
                setSuccess(false)
                setMsg(validationData['error'])
            }
        } else {
            const reCaptchaToken = await verifyReCaptcha()
            const verifyRecaptcha = await UseVerifyReCaptchaAPI({token : reCaptchaToken})

            if (verifyRecaptcha?.data?.success) {
                const response = await UseEmailAPI(values)
        
                if (response.status === 200) {
                    setSuccess(true)
                    setMsg(validationData['success'])
                    TrackGAEvent('form_submit', 'Planned Retirement', window.location.pathname)
                    form.reset()
                } else {
                    setSuccess(false)
                    setMsg(validationData['error'])
                }
            } else {
                setSuccess(false)
                setMsg(verifyRecaptcha?.data?.msg)
            }
        }

    }

    const handleSubmit = (e) => {
        e.stopPropagation()
        e.preventDefault()
        setSuccess(false)
        let obj = {}
        const form = e.target.closest('.customForms')
        setCurrentForm(form)
        const formDetails: any = new FormData(form)
        for (const [key, value] of formDetails) {
            obj[key] = value
            validateFields(key, value)
        }
        setInvalidFocus(true)
        validateAimToRetire(obj)
        validateDOB(obj)
        const success = Object.values(validationData).filter((item) => item.error)
        if(success.length === 0 && chkbox) {
            submitForm(obj, form)
        } else if (!chkbox) {
            setChkMsg(true)
        }
    }

    const onFieldsChange = debounce((name, value) => {
        if (!start) { 
            TrackGAEvent('form_start', 'Planned Retirement', window.location.pathname)
            setStart(true)
        }
        validateFields(name, value)
    }, 1000)

    const handleCheckbox = (checked) => {
        setChkbox(checked)
        setChkMsg(false)
    }

    useEffect(() => {
        if (invalidFocus && currentForm !== null) {
            const errorElement: any = currentForm.querySelector('.invalid')
            errorElement !== null && errorElement.focus()
            setInvalidFocus(false)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [validationData, invalidFocus])

    return (<>
        <div data-testid="plannedRetirementForm" className="layoutSpacing formsDiv">
            {!success && 
                <div id="plannedRetirement" className="w-100 layout essForms sectionBottomSpacing">
                    <div className="text-right">
                    <Paragraph content="* Mandatory fields" classes="!text-base" />
                    </div>
                    <form className="customForms">
                        {data?.data?.fields?.map((field, index) => (<div key={`${field.id}-${index}`}>
                            {field?.id !== "componentName" && <>
                                {field?.type === 'Array' && 
                                    <DropdownComp 
                                        label={`${field?.name} ${field?.required || validationData[field?.id]?.required ? '*' : ''}`}
                                        name={field?.id}
                                        error={validationData[field?.id]?.error}
                                        errorMessage={validationData[field?.id]?.errorMsg}
                                        helpText={field?.helpText}
                                        options={field?.items?.validations[0].in}
                                        withCheckBox={false}
                                        required={field?.required}
                                        onSelect={(value)=> onFieldsChange(field?.id, value)}
                                    />
                                }
                                {field?.type === 'Symbol' && <>
                                    <InputComp
                                        label={`${field?.name} ${field?.required || validationData[field?.id]?.required ? '*' : ''}`}
                                        name={field?.id}
                                        placeholder={field?.name}
                                        error={validationData[field?.id]?.error}
                                        errorMessage={validationData[field?.id]?.errorMsg}
                                        helpText={field?.helpText}
                                        variant="text"
                                        required={field?.required}
                                        onChange={(name, value)=> onFieldsChange(name, value)}
                                    />
                                </>}
                                {field?.type === 'Integer' && 
                                    <InputComp
                                        label={`${field?.name} ${field?.required || validationData[field?.id]?.required ? '*' : ''}`}
                                        name={field?.id}
                                        placeholder={field?.name}
                                        error={validationData[field?.id]?.error}
                                        errorMessage={validationData[field?.id]?.errorMsg}
                                        helpText={field?.helpText}
                                        variant="text"
                                        required={field?.required}
                                        onChange={(name, value)=> onFieldsChange(name, value)}
                                    />
                                }
                                {field?.type === 'Text' && 
                                    <TextAreaWithClose
                                        label={`${field?.name} ${field?.required || validationData[field?.id]?.required ? '*' : ''}`}
                                        name={field?.id}
                                        placeholder={field?.name}
                                        className=""
                                        error={validationData[field?.id]?.error}
                                        errorMessage={validationData[field?.id]?.errorMsg}
                                        helpText={field?.helpText}
                                        onChange={(name, value)=> onFieldsChange(name, value)}
                                    />
                                }
                                {field?.type === 'Date' && 
                                    <InputComp
                                        label={`${field?.name} ${field?.required || validationData[field?.id]?.required ? '*' : ''}`}
                                        name={field?.id}
                                        placeholder={field?.name}
                                        error={validationData[field?.id]?.error}
                                        errorMessage={validationData[field?.id]?.errorMsg}
                                        helpText={field?.helpText}
                                        variant="date"
                                        required={field?.required}
                                        onChange={(name, value)=> onFieldsChange(name, value)}
                                    />
                                }
                                </>
                            }
                            </div>
                        ))}
                        <Selector
                            type="checkbox"
                            label={validationData['consent']?.text}
                            value={`${chkbox}`}
                            name={'consent'}
                            checked={chkbox ? true : false}
                            onChange={(checked) =>
                                handleCheckbox(checked)
                            }
                            className="!p-0"
                        />
                        {chkMsg && <Paragraph content={validationData['consent'].validationMsg} classes={`!text-danger-100`} />}
                        {!success && <Paragraph content={msg} classes={`${validationData['success'] === msg ? '!text-success-100' : '!text-danger-100'}`} />}
                        <div className="w-max" onClick={(e) => handleSubmit(e)}>
                            <PrimaryButton variant="dark" name="Submit" buttonType="submit" classes="mt-24" />
                        </div>
                    </form>
                </div>
            }
            {success && <SuccessBlock content={{title: validationData['successTitle'],description: validationData['successMsg']}} />}
            {start && <Modal />}
        </div>
    </>)
}

const PlannedRetirementForm = () => {
    return (
        <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}>
            <PlannedRetirement />
        </GoogleReCaptchaProvider>
    )
}


export default PlannedRetirementForm