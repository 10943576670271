import * as React from "react";
import Headings from "../../Generic/Heading";
import { ReactComponent as AccordionPlusIcon } from "../../../Assets/accordionPlus.svg";
import { ReactComponent as AccordionMinusIcon } from "../../../Assets/accordionMinus.svg";
import "./style.css";
import OutlineButtonWithIcon from "../../Generic/Button/Outline/ButtonWithIcon";
import Paragraph from "../../Generic/Paragraph";
interface Props {
  content?: any;
}

/**
 * Accordion Component
 * @param content - Content from the CMS.
 */

const Accordion = ({ content }: Props) => {
  const toggleQuickLinks = (e: any) => {
    e.stopPropagation();
    const parentEle = e.target.closest(".list");
    parentEle.classList.toggle("active");
  };

  return (
    <>
      <div data-testid="accordion"
        className={`layoutSpacing ${content.sectionBackgroundColor !== 'bg-white' ? 'sectionSpacing sectionBottomMargin' : 'sectionBottomSpacing'} ${
          content.sectionBackgroundColor === "LightPurple"
            ? "bg-purple-50"
            : "bg-white"
        }`}
      >
        <div className="w-100 layout xl:flex gap-24">
          <div className="w-full md:w-75 xl:w-33 pb-24 xl:pb-0">
            {content?.headline && (
              <Headings
                content={content?.headline}
                variant="H2"
                classes="text-purple-100"
              />
            )}
            {content?.subheadline && <Headings variant="H3" content={content?.subheadline} classes="text-purple-100" />}
            {content?.description && (
              <Paragraph content={content?.description} classes="pb-16" />
            )}
          </div>
          <div 
            className={`w-full xl:w-2/3 2xl:4/5 accordion border border-neutral-50 rounded-2xl ${
              content.cardBackgroundColor === "White"
                ? "bg-white"
                : "bg-purple-50"
            }`}
          >
            {content.features.map((item, index) => {
              return (
                <div
                  key={`accordion-${index}`}
                  className="list border-b border-neutral-50 last:border-none"
                >
                  <div
                    className="title cursor-pointer flex justify-between items-center p-32"
                    onClick={(e) => {
                      toggleQuickLinks(e);
                    }}
                  >
                    <Headings
                      content={item.fields.accordionHeadline[0].fields.headline}
                      variant="H4"
                      classes="text-purple-100 pr-12 !pb-0"
                    />
                    <AccordionPlusIcon />
                    <AccordionMinusIcon />
                  </div>
                  <div data-testid="accordionContaner" className={`content w-100`}>
                    {item.fields?.accordionItem && (
                      <div className={`p-32 pt-0`}>
                        <Paragraph
                          content={
                            item.fields?.accordionItem[0].fields
                              ?.accordionContent
                          }
                        />
                        {item.fields?.accordionItem[0].fields?.button &&
                        <OutlineButtonWithIcon
                          name={
                            item.fields?.accordionItem[0].fields?.button[0]
                              .fields.ctaText
                          }
                          link={
                            item.fields?.accordionItem[0].fields?.button[0]
                              .fields.buttonLink
                          }
                          variant={
                            item.fields?.accordionItem[0].fields?.button[0]
                              .fields.ctaButtonVariety === "OutlineDark"
                              ? "dark"
                              : "light"
                          }
                          classes="mb-8"
                        />
                      }
                      </div>
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default Accordion;
