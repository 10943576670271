import * as React from "react";
import Headings from "../../Generic/Heading";
import Paragraph from "../../Generic/Paragraph";
import TextButtonWithIcon from "../../Generic/Button/Text/ButtonWithIcon";
import Image from "../../Generic/Image";
import Video from "../../Generic/Video";
import { Helmet } from "react-helmet-async";

interface Props {
  content?: any;
}

/**
 * Listing Component
 * @param content - Content from the CMS.
 */
const Listing = ({ content }: Props) => {
  const formatDate = (publishDate) => {
    return `${new Date(publishDate).toLocaleDateString('default', {day: '2-digit'})} ${new Date(publishDate).toLocaleDateString('default', {month: 'short'})} ${new Date(publishDate).toLocaleDateString('default', {year: 'numeric'})}`
  }

  return (
    <>
      <div
        data-testid="listing"
        className={`flex flex-col lg:flex-row items-center bg-white border-b border-neutral-50 py-32 gap-40 last:border-0`}
      >
        {content?.iconUrl !== null && (
          <div className="flex justify-start lg:justify-center w-full lg:w-[200px]">
            <Image
              imageUrl={content?.iconUrl}
              altText="Listing Image"
              width="96"
            />
          </div>
        )}

        {content?.imageUrl !== null && (
          <div className="flex justify-start lg:justify-center w-full lg:w-[200px]">
            <Image
              imageUrl={content?.imageUrl}
              altText="Listing Image"
            />
          </div>
        )}

        <div className={content?.imageUrl !== null ? "flex-1" : "flex-1 w-100"}>
          <div className="">
            {content?.tag !== null && 
              <Paragraph
                content={content?.tag}
                classes="!text-base text-neutral-90 pb-16"
              />
            }
            {content?.publishDate !== null && 
              <Paragraph
                content={formatDate(content?.publishDate)}
                classes="!text-base text-neutral-90 pb-16"
              />
            }
            {content?.timeToRead !== null && 
              <Paragraph
                content={content?.timeToRead}
                classes="!text-base text-neutral-90 pb-24"
              />
            }
            {content?.headLine !== null && (
              <Headings
                content={content?.headLine}
                variant="H4"
                classes="text-black !pb-16"
              />
            )}
            {content?.contentDescription !== null && (
              <Paragraph
                content={content?.contentDescription}
                classes="text-black pb-32"
              />
            )}
            {content?.videoPodcastUrl !== null && content?.buzzsproutId === null && (
              <div className={`w-100 md:w-75 xl:w-60`}>
                <Video
                  videoUrl={content?.videoPodcastUrl}
                  classes={`mt-16`}
                />
              </div>
            )}
            {content?.videoPodcastUrl !== null && content?.buzzsproutId !== null && <>
              <div id={`buzzsprout-player-${content?.buzzsproutId}`}>&nbsp;</div>
              <Helmet>
                <script src={`${content?.videoPodcastUrl}?container_id=buzzsprout-player-${content?.buzzsproutId}&player=small`} type="text/javascript"></script>
              </Helmet>
            </>}
            {content?.videoPodcastUrl === null && <>
            {content?.contentCtaLinkToPage !== null &&
              Array.isArray(content?.contentCtaLinkToPage) ? (
                content?.contentCtaLinkToPage.map((link: any, index) => {
                  return (
                    <span key={`link-${index}`}>
                      <TextButtonWithIcon
                        name={link.fields.ctaText}
                        underline={true}
                        variant={
                          link.fields.ctaButtonVariety === "TextDark"
                            ? "dark"
                            : "light"
                        }
                        link={
                          link.fields.buttonLinkExternal &&
                          link.fields.buttonLinkExternal
                        }
                      />
                    </span>
                  );
                })
              ) : (
              <span>
                <TextButtonWithIcon
                  name="Go to page"
                  underline={true}
                  variant="dark"
                  link={content?.contentCtaLinkToPage}
                />
              </span>
            )}</>}
          </div>
        </div>
      </div>
    </>
  );
};

export default Listing;
