import React, { useEffect, useState } from "react";
import Charts from "../Charts";
import Headings from "../../Generic/Heading";
import Paragraph from "../../Generic/Paragraph";
import { colorSet } from "./index.data";

interface Props {
  content: any;
}

const ChartsContainer = ({ content }: Props) => {
  const [chartData, setChartData] = useState({});
  const [chartType, setChartType] = useState(
    content.chartType ? content.chartType : "bar"
  );
  const [isPercentageValue, setIsPercentageValue] = useState(false);
  const [pieChartLabels, setPieChartLabels] = useState([]);

  useEffect(() => {
    if (!content.referenceName) {
      return;
    } else {
      setChartType(content.chartType);
      content.referenceName.map((richTextData) => {
        if (richTextData.fields.chartDetails) {
          richTextData.fields.chartDetails.content.map((richText) => {
            extractTableDataFromRichtext(richText);
          });
        }
      });
    }
  }, content.referenceName);

  const getChartsData = (tableData) => {
    let labels = [];
    const datasets = [];
    if (tableData.length === 0) return { labels: [], datasets: [] };
    if (chartType === "doughnut" || chartType === "pie") {
      const data = [];
      const pieLabels = [];
      tableData.forEach((row) => {
        labels.push(row[0] + " " + row[2]);
        data.push(row[1]);
        pieLabels.push({
          label: row[0],
          value: row[1],
          range: row[2],
        });
      });
      datasets.push({
        label: ["Pie Chart"],
        data: data.map((el) => {
          const isPercentage = /%/.test(el);
          setIsPercentageValue(isPercentage);
          return parseFloat(el).toString() || "";
        }),
      });
      setPieChartLabels(pieLabels);
    } else {
      labels = tableData.slice(1).map((row) => row[0]);

      for (let i = 1; i < tableData[0].length; i++) {
        datasets.push({
          label: tableData[0][i],
          data: tableData.slice(1).map((row) => {
            const isPercentage = /%/.test(row[i]);
            setIsPercentageValue(isPercentage);
            return parseFloat(row[i]).toString() || "";
          }),
        });
      }
    }

    return { labels, datasets };
  };

  const extractTableDataFromRichtext = (richtextData) => {
    const tableNodes = richtextData.nodeType === "table";
    if (!richtextData || !tableNodes) return [];
    const tableData = richtextData.content.map((row) => {
      return row.content.map((cell) => {
        const paragraph = cell?.content?.[0];

        return paragraph?.nodeType === "paragraph" &&
          paragraph.content?.[0]?.nodeType === "text"
          ? paragraph.content[0].value
          : "";
      });
    });
    setChartData(getChartsData(tableData));

    return tableData;
  };

  return (
    <div className="">
      <div className="layoutSpacing py-48 border-b border-neutral-50">
        <div className="layout mb-24">
          {content?.headline && (
            <Headings variant="h4" content={content?.headline} />
          )}
          {content.description && (
            <Paragraph content={content.description} classes="mb-24" />
          )}
          {/* Check Table Data */}
          {/* <Paragraph
            content={documentToHtmlString(
              content.referenceName[0].fields.chartDetails
            )}
          /> */}
          {chartType === "doughnut" || chartType === "pie" ? (
            <div className="flex flex-wrap">
              <div className="w-full md:w-40 px-16 md:px-32 xl:pl-20">
                <Charts
                  type={chartType}
                  data={chartData}
                  isPercentageValue={isPercentageValue}
                />
              </div>
              <div className="w-full md:w-1/2 flex items-center mt-32 md:mt-0 px-16 md:px-48">
                <div className="w-full">
                  {pieChartLabels.length &&
                    pieChartLabels.map((item, index) => {
                      return (
                        <div
                          className="flex justify-between items-center text-sm py-1"
                          key={`key-${index}`}
                        >
                          <span className="w-2/3 flex items-center">
                            <span
                              className="inline-block w-5 h-5 rounded-full mr-3"
                              style={{
                                backgroundColor: colorSet[index],
                              }}
                            ></span>
                            {item.label}
                          </span>
                          <span className="w-2/4 flex justify-between">
                            <span>{item.value}</span>
                            <span>{item.range}</span>
                          </span>
                        </div>
                      );
                    })}
                </div>
              </div>
            </div>
          ) : (
            <Charts
              type={chartType}
              data={chartData}
              isPercentageValue={isPercentageValue}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default ChartsContainer;
