import * as React from "react";
import { useEffect, useState } from "react";
import Listing from "../Listing";

interface Props {
  content?: any;
  parentSlug?: string
}

/**
 * News Component
 * @param content - Content from the CMS.
 */
const NewsBlock = ({
  content,
  parentSlug
}: Props) => {
  const [data, setData] = useState([])

  useEffect(() => {
    const list = []
    content?.details?.forEach((item) => {
      const pageBannerContent = item?.fields?.pageContainer[0].fields
      const headlineWithContent = item?.fields?.pageContainer[0].fields?.headlineWithContent[0]?.fields

      list.push({
        tag: null,
        iconUrl: null,
        imageUrl: headlineWithContent?.assets ? headlineWithContent?.assets[0]?.fields?.file?.url : null,
        headLine: headlineWithContent?.headline ? headlineWithContent?.headline : null,
        contentDescription: pageBannerContent?.description ? pageBannerContent?.description : null,
        contentCtaLinkToPage: item?.fields?.slug ? `${parentSlug}/${item?.fields?.slug}` : null,
        publishDate: pageBannerContent?.publicationDate ? pageBannerContent?.publicationDate : null,
        timeToRead: pageBannerContent?.timeToReadText ? pageBannerContent?.timeToReadText : null,
        videoPodcastUrl: null,
        buzzsproutId: null
      })
    })
    setData(list)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (<>
    <div className={`layoutSpacing layout`}>
      {data?.map((item, index) => 
        <Listing key={`${item?.headLine}-${index}`} content={item} />
      )}
    </div>
  </>)
};

export default NewsBlock;
