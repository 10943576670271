const FormValidations = () => {

    let validations = []
    validations["successTitle"] = "Thank You"
    validations["successMsg"] = `Your online form submission has been received. We appreciate your time and will action your request as a priority.<br/><br/> Please allow a few days for a response (if required).`
    validations["error"] = "Something went wrong. Please try again."
    validations["distributionOrganisation"] = {
        id: "distributionOrganisation",
        pattern: "",
        helpText: "",
        required: true,
        error: false,
        errorMsg: "",
        requiredMsg: "This field is mandatory",
        validationMsg: ""
    }
    validations["yourName"] = {
        id: "yourName",
        pattern: /^[a-zA-Z ]{2,100}$/,
        helpText: "",
        required: true,
        error: false,
        errorMsg: "",
        requiredMsg: "This field is mandatory",
        validationMsg: "Accepts only alphabets and space, (min: 10, max: 100 characters allowed)"
    }
    validations["emailAddressTitle"] = {
        id: "emailAddressTitle",
        pattern: /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/,
        helpText: "",
        required: true,
        error: false,
        errorMsg: "",
        requiredMsg: "This field is mandatory",
        validationMsg: "Invalid email address. Format: xyz@example.com | xyz@example.com.au"
    }
    validations["phoneNumberTitle"] = {
        id: "phoneNumberTitle",
        pattern: /^\+([0-9]{1,2})?([0-9]{10})$/,
        helpText: "",
        required: true,
        error: false,
        errorMsg: "",
        requiredMsg: "This field is mandatory",
        validationMsg: "Accepts only numbers[0-9] and '+' (min: 10, max: 13 characters allowed) Format: +61123456789"
    }
    validations["startDateOfTheSignificantDealing"] = {
        id: "startDateOfTheSignificantDealing",
        pattern: "",
        helpText: "",
        required: true,
        error: false,
        errorMsg: "",
        requiredMsg: "This field is mandatory",
        validationMsg: "",
        format: "date"
    }
    validations["endDateOfTheSignificantDealing"] = {
        id: "endDateOfTheSignificantDealing",
        pattern: "",
        helpText: "",
        required: true,
        error: false,
        errorMsg: "",
        requiredMsg: "This field is mandatory",
        validationMsg: "End date should not be older than the start date",
        format: "date"
    }
    validations["productOfConcern"] = {
        id: "productOfConcern",
        pattern: "",
        helpText: "",
        required: true,
        error: false,
        errorMsg: "",
        requiredMsg: "This field is mandatory",
        validationMsg: ""
    }
    validations["descriptionOfTheSignificantDealing"] = {
        id: "descriptionOfTheSignificantDealing",
        pattern: "",
        helpText: "",
        required: true,
        error: false,
        errorMsg: "",
        requiredMsg: "This field is mandatory",
        validationMsg: ""
    }
    validations["whyIsTheDealingSignificant"] = {
        id: "whyIsTheDealingSignificant",
        pattern: "",
        helpText: "",
        required: true,
        error: false,
        errorMsg: "",
        requiredMsg: "This field is mandatory",
        validationMsg: ""
    }
    validations["howWasTheSignificantDealingIdentified"] = {
        id: "howWasTheSignificantDealingIdentified",
        pattern: "",
        helpText: "",
        required: true,
        error: false,
        errorMsg: "",
        requiredMsg: "This field is mandatory",
        validationMsg: ""
    }
    validations["Textarea"] = {
        id: "Textarea",
        pattern: "",
        helpText: "",
        required: true,
        error: false,
        errorMsg: "",
        requiredMsg: "This field is mandatory",
        validationMsg: ""
    }

    return { validations }
    
}

export { FormValidations }