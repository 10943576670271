import { useEffect, useRef, useState } from "react";
import axios from "axios";
import CryptoJS from "crypto-js";
import * as jose from "jose";

/**
 * Content API to get based on entries and content type
 * @param apiType - Type of the API. Expected Values : 'entries' | 'contentType'.
 * @param contentTypeId (Optional) - Content Type Id of the content.
 * @param slug (Optional) - Slug of the page.
 * @param preview (Optional) - Enables Live preview. Expected Values : 'true' | 'false'.
 */

const API_BASE_URL = process.env.REACT_APP_PWS_API_URL;

// Fetch the MSAL access token from the server
let msalAccessToken = null;
const fetchAccessToken = async () => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_PWS_API_URL}/api/get-access-token`
    )
    if (response?.status !== 200) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    // const data = await response.json();
    if (!response?.data?.accessToken) {
      throw new Error(`Access token is missing in response.`);
    }
    // Save the token and expiration info in sessionStorage
    msalAccessToken = response?.data?.accessToken;
    const tokenData = {
      accessToken: response?.data?.accessToken,
      expiresOn: new Date(response?.data?.expiresOn).getTime(), // Add expiration field from response
    };
    window.sessionStorage.setItem("msalAccessToken", JSON.stringify(tokenData));
  } catch (error) {
    window.sessionStorage.removeItem("msalAccessToken");
    console.error(error);
  }
};

// Function to check token validity and refresh if necessary
const getAccessToken = async () => {
  const tokenDataString = window.sessionStorage.getItem("msalAccessToken");
  if (tokenDataString) {
    const tokenData = JSON.parse(tokenDataString);
    if (tokenData.expiresOn > Date.now()) {
      msalAccessToken = tokenData.accessToken;
    } else {
      await fetchAccessToken();
    }
  } else {
    await fetchAccessToken();
  }
};

// Encrypting data using AES
function encryptPayload(payload, secretKey) {
  const encrypted = CryptoJS.AES.encrypt(
    JSON.stringify(payload),
    secretKey
  ).toString();

  return encrypted;
}

// const generateJWT = async (payload) => {
//   const JWTSecret = new TextEncoder().encode(process.env.REACT_APP_JWT_SECRET);
//   const alg = "HS256";

//   const jwt = await new jose.SignJWT(payload)
//     .setProtectedHeader({ alg })
//     .setIssuedAt()
//     .setIssuer(process.env.REACT_APP_JWT_ISSUER)
//     .setAudience(process.env.REACT_APP_JWT_AUDIENCE)
//     .setExpirationTime(process.env.REACT_APP_JWT_EXPIRATION_TIME)
//     .sign(JWTSecret);

//   return jwt;
// };

const useContentAPI = (
  apiType: string,
  contentTypeId: string,
  slug: string,
  preview: boolean
) => {
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const prevSlug = useRef("");
  const prevContentTypeID = useRef("");

  const entriesAPI = async () => {
    try {
      // const payload = { pws: true };
      // const jwtToken = await generateJWT(payload);
      // wait to complete 'getAccessToken'
      await getAccessToken();
      
      const options = {
        headers: {
          Authorization: `Bearer ${msalAccessToken}`,
        },
      };

      const response = await axios.get(
        `${API_BASE_URL}/api/entries/${slug}${preview ? "?preview=true" : ""}`,
        { ...options }
      );
      setData(response);
    } catch (err) {
      setError(err);
    }
    setIsLoading(false);
  };

  const contentTypeAPI = async () => {
    try {
      // const payload = { pws: true };
      // const jwtToken = await generateJWT(payload);

      const options = {
        headers: {
          Authorization: `Bearer ${msalAccessToken}`,
        },
      };

      const response = await axios.get(
        `${API_BASE_URL}/api/entries/contentType/${contentTypeId}${
          preview ? "?preview=true" : ""
        }`,
        { ...options }
      );
      setData(response);
    } catch (err) {
      setError(err);
    }
    setIsLoading(false);
  };

  const contentModelAPI = async () => {
    try {
      // const payload = { pws: true };
      // const jwtToken = await generateJWT(payload);

      const options = {
        headers: {
          Authorization: `Bearer ${msalAccessToken}`,
        },
      };

      const response = await axios.get(
        `${API_BASE_URL}/api/contentModel/${contentTypeId}${
          preview ? "?preview=true" : ""
        }`,
        { ...options }
      );
      setData(response);
    } catch (err) {
      setError(err);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (apiType === "entries") {
      if (
        prevSlug.current !== slug &&
        prevContentTypeID.current !== contentTypeId
      ) {
        prevSlug.current = slug;
        prevContentTypeID.current = contentTypeId;
        entriesAPI();
      }
    } else if (apiType === "contentType") {
      if (prevContentTypeID.current !== contentTypeId) {
        prevSlug.current = slug;
        prevContentTypeID.current = contentTypeId;
        contentTypeAPI();
      }
    }
    if (apiType === "contentModel") {
      if (prevContentTypeID.current !== contentTypeId) {
        prevSlug.current = slug;
        prevContentTypeID.current = contentTypeId;
        contentModelAPI();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { data, isLoading, error };
};

const UseEmailAPI = async (payload) => {
  try {
    const encryptedPayload = encryptPayload(payload, msalAccessToken);
    const options = {
      headers: {
        Authorization: `Bearer ${msalAccessToken}`,
        "Content-Type": "application/json",
      },
    };
    const payloadData = JSON.stringify({
      key: encryptedPayload,
    });
    const response = await axios.post(
      `${API_BASE_URL}/api/formSubmission`,
      payloadData,
      {
        ...options,
      }
    );

    return response;
  } catch (err) {
    return err;
  }
};

const UseDailyUnitPriceAPI = async () => {
  try {
    // const jwtToken = await generateJWT({});

    const options = {
      headers: {
        Authorization: `Bearer ${msalAccessToken}`,
      },
    };
    const response = await axios.get(`${API_BASE_URL}/api/dailyUnitPrice`, {
      ...options,
    });

    return response;
  } catch (err) {
    return err;
  }
};

const UseVerifyReCaptchaAPI = async (payload) => {
  try {
    // const jwtToken = await generateJWT(payload);
    const encryptedPayload = encryptPayload(payload, msalAccessToken);

    const options = {
      headers: {
        Authorization: `Bearer ${msalAccessToken}`,
        "Content-Type": "application/json",
      },
    };
    
    const payloadData = JSON.stringify({
      key: encryptedPayload,
    });

    const response = await axios.post(`${API_BASE_URL}/api/verify/reCaptcha`, payloadData, {
      ...options,
    });

    return response;
  } catch (err) {
    return err;
  }
};

const GetEventsDetailsAPI = async () => {
  try {
    // const jwtToken = await generateJWT({});

    const options = {
      headers: {
        Authorization: `Bearer ${msalAccessToken}`,
      },
    };
    const response = await axios.get(`${API_BASE_URL}/api/getEventDetails`, {
      ...options,
    });

    return response;
  } catch (err) {
    return err;
  }
};

const SaveEventsDetailsAPI = async (payload) => {
  try {
    // const jwtToken = await generateJWT(payload);
    const encryptedPayload = encryptPayload(payload, msalAccessToken);
    const options = {
      headers: {
        Authorization: `Bearer ${msalAccessToken}`,
        "Content-Type": "application/json",
      },
    };
    const payloadData = JSON.stringify({
      key: encryptedPayload,
    });
    const response = await axios.post(
      `${API_BASE_URL}/api/saveEventDetails`,
      payloadData,
      {
        ...options,
      }
    );

    return response;
  } catch (err) {
    return err;
  }
};

export {
  useContentAPI,
  UseEmailAPI,
  UseDailyUnitPriceAPI,
  UseVerifyReCaptchaAPI,
  GetEventsDetailsAPI,
  SaveEventsDetailsAPI,
};
