import * as React from "react";
import { useEffect, useRef, useState } from "react";
import TabSelector from "../../Generic/TabSelector";
import EventCards from "./Cards";
import Headings from "../../Generic/Heading";
import Paragraph from "../../Generic/Paragraph";
import { GetEventsDetailsAPI, useContentAPI } from "../../../Hooks/useAPI";
import EventDetails from "./Detail";
import resolveResponse from "contentful-resolve-response";

interface Props {
  content?: any;
  background?: string;
  cardBackground?: string;
  columnLayout?: string;
  border?: boolean;
}

/**
 * Event Cards Component
 * @param content - Content from the CMS.
 * @param background (optional) - Background of the Card Container. Expected values: "white" | "teal" | "purple" | "orange", Default: "white"
 * @param cardBackground (optional) - Background of the Card. Expected values: "white" | "teal" | "purple" | "orange", Default: "white"
 * @param columnLayout (optional) - No. of Columns Layout. Expected values: "2" | "3" | "4" | "orange", Default: "white"
 * @param border (optional) - Outline/Border of the Card. Expected values: true | false, Default: false
 */

const Events = ({
  content,
  background = "white",
  cardBackground = "white",
  columnLayout = "2",
  border = false,
}: Props) => {
  const [selectedTabs, setSelectedTabs] = useState([]);
  const [eventDetailsData, setEventDetailsData] = useState([])
  const [pageNumber, setPageNumber] = useState(1)
  const [contentData, setContentData] = useState([])
  const [dbData, setDbData] = useState([])
  const { data } = useContentAPI("contentType", "eventsBlockComponent", "", false)
  const bgColor =
    background === "purple"
      ? "bg-purple-10"
      : background === "orange"
      ? "bg-orange-10"
      : background === "teal"
      ? "bg-teal-10"
      : "bg-white";

  const api = useRef("")

  const renderDBData = async () => {
    const db = await GetEventsDetailsAPI()
    if (db?.data.length > 0) {
        setDbData(db?.data)
    } else {
        setDbData([])
    }
  }

  const checkCapacity = (eventId: string, location: string, capacity: number) => {
    const locationType = location.replace('-', '')
    const filteredData = dbData.filter((item) => item?.EventID === `${eventId}` && item?.Type === locationType)
    if (filteredData.length === 0) {
      return true
    } else {
      const attendee = filteredData[0]?.Total_Attendee
      return attendee < capacity
    }
  }

  const getTotalAttendee = (eventId: string, location: string) => {
    const locationType = location.replace('-', '')
    const filteredData = dbData.filter((item) => item?.EventID === `${eventId}` && item?.Type === locationType)
    if (filteredData.length === 0) {
      return 0
    } else {
      const attendee = filteredData[0]?.Total_Attendee
      return attendee
    }
  }

  useEffect(() => {
    if (!dbData || dbData.length === 0) return
    const eventData = []

    const resolver = {
      items: data?.data?.items,
      includes: data?.data?.includes,
    };
    const response = resolveResponse(resolver);

    // filter only upcoming events and add capacity and total attendee
    response?.forEach((evt) => {
      const today = new Date()
      const startDate = new Date(evt?.fields?.startDate)
      if (startDate >= today) {
        const obj = {
          ...evt?.fields,
          capacity: checkCapacity(evt?.fields?.eventId, evt?.fields?.eventLocation, evt?.fields?.maximumNumberOfAttendees),
          totalAttendee: getTotalAttendee(evt?.fields?.eventId, evt?.fields?.eventLocation)
        }
        const obj1 = {
          fields: obj,
          sys: evt?.sys
        }
        eventData.push(obj1)
      }
    })
    setContentData(eventData)

    if (window.location.search.includes('eventId')) {
      const queryString = window.location.search
      const urlParams = new URLSearchParams(queryString)
      const evtId = Number(urlParams?.get('eventId'))
      const details = eventData?.filter((item) => item?.fields?.eventId === evtId)
      setEventDetailsData(details)
    }
    
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dbData, data])

  useEffect(() => {
    if (api.current === '') {
      renderDBData()
      api.current = 'done'
    }
    
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const targetElement: any = document.querySelector('.backToEvents')
    const top = targetElement?.offsetTop
    window.scrollTo({top: top, behavior: 'smooth'})
    // const parentElement: any = document.querySelector('.events-page')
    // const parentTop = parentElement?.offsetTop
    // window.scrollTo({top: parentTop, behavior: 'smooth'})
    
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventDetailsData])

  return (
    <>
      <div data-testid="event" className={`${bgColor} events-page`}>
        {(content?.headline || content?.description) && (
          <div className="layoutSpacing">
            <div className="w-full layout">
              <div className="w-full md:w-3/4">
                {content?.headline && (
                  <Headings
                    content={content?.headline}
                    variant="H2"
                    classes="text-purple-100"
                  />
                )}
                {content?.description && (
                  <Paragraph
                    content={content?.description}
                    classes="pb-16"
                  />
                )}
              </div>
            </div>
          </div>
        )}

        {eventDetailsData.length === 0 ? 
          <>
          <TabSelector
            content={content?.features}
            selectedTabs={selectedTabs}
            setSelectedTabs={setSelectedTabs}
            eventsData={contentData}
            setPageNumber={setPageNumber}
            data-testid="tabSelector"
          />

          <EventCards
            border={true}
            content={content?.features}
            eventsData={contentData}
            selectedTabs={selectedTabs}
            carousel={content?.features[0]?.fields?.carousel}
            pageNumber={pageNumber}
            setPageNumber={setPageNumber}
            data-testid="eventCardss"
            dbData={dbData}
          />
        </>
        : 
          <EventDetails content={content?.features} eventDetailsData={eventDetailsData} eventsData={contentData} data-testid="eventDetails"/>
        }
      </div>
    </>
  );
};

export default Events;
