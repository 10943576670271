import * as React from "react";
import { useEffect } from "react";
import "./styles.css";
// import { Helmet } from "react-helmet-async";
// import axios from "axios"

interface Props {
    content?: any
}

/**
 * Calculator Details Component
 */
const CalculatorDetails = ({content}: Props) => {

    let calcList = []
    calcList["retirement-calculator"] = process.env.REACT_APP_RETIREMENT_CALCULATOR
    calcList["salary-sacrifice-calculator"] = process.env.REACT_APP_SALARY_SACRIFICE_CALCULATOR
    calcList["insurance-premium-calculator"] = process.env.REACT_APP_INSURANCE_PREMIUM_CALCULATOR
    calcList["risk-profile-calculator"] = process.env.REACT_APP_RISK_PROFILE_CALCULATOR
    calcList["ntc-calculator"] = process.env.REACT_APP_NTC_CALCULATOR
    calcList["small-change-big-savings-calculator"] = process.env.REACT_APP_SMALL_CHANGE_BIG_SAVINGS_CALCULATOR
    calcList["daily-unit-price"] = process.env.REACT_APP_DAILY_UNIT_PRICE


    const calcSelected = content?.selectACalculator?.toLowerCase()?.replace(/ /g, "-")?.trim()

  const objectRef = React.useRef(null);

  useEffect(() => {
    // will add code for height adjust.
  }, []);

  return (
    <>
      
      <div className={`layoutSpacing`}>
        <div data-testid="calculatorDetails" className="w-100 layout sectionBottomSpacing">
          {/* <div className="iframeDiv" dangerouslySetInnerHTML={{__html: data}}></div> */}
          {/* <iframe className="iframe-calc" src={process.env.REACT_APP_RETIREMENT_CALCULATOR} title="calculator"></iframe> */}

          <object
            ref={objectRef}
            data={calcList[calcSelected]}
            type="text/html"
            width="100%"
            style={{
              minHeight: "800px",
              border: "none",
            }}
          >
            Error: Accessing embedded content
          </object>
        </div>
      </div>
    </>
  );
};

export default CalculatorDetails;
