import * as React from "react"
import Headings from "../../Generic/Heading"
import { ButtonWrapper } from "../../Generic/Button/RenderButton"
import Paragraph from "../../Generic/Paragraph"

interface Props {
    content?: any
}

/**
 * Page Not Found Component
 * @param content - Content from the CMS.
 */
const PageNotFound = ({content}: Props) => {
    const columns = content?.cardLayout === '3' ? 'xl:w-[32%]' : content?.cardLayout === '4' ? 'xl:w-[23%]' : 'xl:w-[49%]'

    return (<>
        <div className="pageNotFound"> 
            <div data-testid="pageNotFound" className={`layoutSpacing w-100 py-80 bg-white`}>
                <div className="layout">
                    <div className={`flex flex-col justify-center items-center gap-16`}>
                        <Headings variant="H1" content={content?.headline} classes="text-black text-center" />
                        <Paragraph content={content?.contentDescription || content?.bodyText  || content?.description} classes="text-lg md:text-h4 pb-8 font-semibold text-neutral-70 text-center" />
                        <ButtonWrapper content={content} columns={columns} />
                    </div>
                </div>
            </div>
        </div>
    </>)
}

export default PageNotFound