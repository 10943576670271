import * as React from "react";
import { useState } from "react";
import Image from "../../Generic/Image";
import Paragraph from "../../Generic/Paragraph";
import PrimaryButtonWithIcon from "../../Generic/Button/Primary/ButtonWithIcon";
import { ReactComponent as TabNavLeft } from "../../../Assets/TabNavLeft.svg";
import { ReactComponent as TabNavRight } from "../../../Assets/TabNavRight.svg";
import { ReactComponent as ArrowUpIcon } from "../../../Assets/arrowUp.svg";
import { ReactComponent as ArrowDownIcon } from "../../../Assets/arrowDown.svg";
import { ReactComponent as CheckPurpleIcon } from "../../../Assets/checkPurple.svg";
import "./style.css";
import { useDeviceDetector } from "../../../Hooks/useDeviceDetector";
import Listing from "../Listing";
import Headings from "../../Generic/Heading";

interface Props {
  content?: any;
  border?: boolean;
}

/**
 * Tabs Component
 * @param content - Content from the CMS.
 */
const TabSelector = ({ content, border = false }: Props) => {
  const { isMobile, isTablet, isDesktop } = useDeviceDetector();
  const [counter, setCounter] = useState(0);
  const [selectedImageUrl, setsSelectedImageUrl] = useState(
    content.tabsGroup[0].fields.icon
      ? content.tabsGroup[0].fields.icon.fields.file.url
      : null
  );
  const [selectedText, setsSelectedText] = useState("All");
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [activeTab, setActiveTab] = useState(0);
  const [tabTitle, setTabTitle] = useState("All");
  const [toggleTabsDD, setToggleTabsDD] = useState(false);
  const totalTabs = content.tabsGroup.length;
  const noOfTabsShown = isMobile ? 1 : isTablet ? 2 : isDesktop ? 5 : 1;

  const toggleTabs = (tabTitle: string, index: number) => {
    setActiveTab(index);
    setTabTitle(tabTitle);
  };

  const toggleMobileTabs = (e: any) => {
    setToggleTabsDD((prev) => !prev);
  };

  const onTabSelectMob = (item, index) => {
    setsSelectedImageUrl(
      item.fields.icon ? item.fields.icon.fields.file.url : null
    );
    setsSelectedText(item.fields.tabTitle);
    setSelectedIndex(index);
    setToggleTabsDD((prev) => !prev);
  };

  const navRight = (e: any) => {
    e.stopPropagation();
    setCounter(counter + 1);
    const tabLayout = e.target.closest(".tabLayout");
    const tabHeading = tabLayout.querySelector(".tabHeading");
    const tabs = tabLayout.querySelectorAll(".tabs");
    const lastTab = tabs[0];
    tabHeading.append(lastTab);
  };

  const navLeft = (e: any) => {
    e.stopPropagation();
    setCounter(counter - 1);
    const tabHeading = document.querySelector(".tabHeading");
    const tabs = document.querySelectorAll(".tabs");
    const lastTab = tabs[tabs.length - 1];
    tabHeading.prepend(lastTab);
  };

  const getListingContent = (item) => {
    const listingData = {
      iconUrl: null,
      publishDate: null,
      tag: null,
      timeToRead: null,
      videoPodcastUrl: null,
      buzzsproutId: null,
      imageUrl: item?.fields?.contentImage?.fields?.file?.url || null,
      headLine: item?.fields?.headline || null,
      contentDescription: item?.fields?.contentDescription || null,
      contentCtaLinkToPage: item?.fields?.contentCtaLinkToPage || null
  }
    return listingData;
  }

  return (
    <>
      <div className="layoutSpacing">
        <div className={`w-100 sectionBottomSpacing layout tabLayout`}>
          {!isMobile && !isTablet && (
            <div
              className={`${
                border ? "border border-neutral-50 rounded-2xl" : ""
              } overflow-hidden`}
            >
              <div
                className={`tabHeading flex border-b border-neutral-50 relative`}
              >
                <div
                  key={`tabHeading-0`}
                  className={`tabs 1 flex flex-1 justify-center items-center gap-24 p-32 cursor-pointer md:min-w-[50%] xl:min-w-[20%] ${
                    activeTab === 0 ? "active" : ""
                  }`}
                  onClick={() => {
                    toggleTabs("All", 0);
                  }}
                >
                  <Paragraph content="All" classes="!text-xl" />
                </div>
                {content.tabsGroup.map((item, index) => {
                  return (
                    <div
                      key={`tabHeading-${index}`}
                      className={`tabs 1 flex flex-1 justify-center items-center gap-24 p-32 cursor-pointer md:min-w-[50%] xl:min-w-[20%] ${
                        activeTab === index + 1 ? "active" : ""
                      }`}
                      onClick={() => {
                        toggleTabs(item.fields.tabTitle, index + 1);
                      }}
                    >
                      {item.fields.icon && (
                        <Image
                          imageUrl={item.fields.icon.fields.file.url}
                          altText="TabIcon"
                          width="30"
                        />
                      )}
                      <Paragraph
                        content={item.fields.tabTitle}
                        classes="!text-xl"
                      />
                    </div>
                  );
                })}
                {counter > 0 && (
                  <div
                    className={`tabNavLeft flex justify-center items-center cursor-pointer absolute top-0 left-0 h-full p-12 pl-32 pr-80 bg-gradient-to-r from-white to-white-100 z-10`}
                    onClick={(e) => {
                      navLeft(e);
                    }}
                  >
                    <TabNavLeft />
                  </div>
                )}
                {totalTabs > noOfTabsShown &&
                  counter >= 0 &&
                  totalTabs - noOfTabsShown !== counter && (
                    <div
                      className={`tabNavRight flex justify-center items-center cursor-pointer absolute top-0 right-0 h-full p-12 pl-80 pr-32 bg-gradient-to-l from-white to-white-100 z-10`}
                      onClick={(e) => {
                        navRight(e);
                      }}
                    >
                      <TabNavRight />
                    </div>
                  )}
              </div>
              {content.tabsGroup.map((item, index) => {
                return (
                  <div
                    key={`tabContent-${index}`}
                    className={`tabContent ${border ? "p-48" : "pt-48"} ${
                      tabTitle === "All"
                        ? "active"
                        : activeTab === index + 1
                        ? "active"
                        : ""
                    }`}
                  >
                    {item.fields.content &&
                      item.fields.content.content.map((textItem, textIndex) => {
                        return (
                          <div key={`key${textIndex}`}>
                            <Paragraph
                              content={textItem}
                              classes="mb-24"
                            />
                          </div>
                        );
                      })}

                    {item.fields.tabContent[0].sys.contentType.sys.id ===
                      "blockInfoFeatureComponent" && (
                      <div className="w-100 layout">
                        <Headings
                          content={item?.fields?.tabTitle}
                          variant="H2"
                          classes="text-purple-100"
                        />
                        {item?.fields?.tabContent.map((item, index) => {
                          return <Listing key={index} content={getListingContent(item)} />;
                        })}
                      </div>
                    )}

                    {item.fields.tabContent[0].sys.contentType.sys.id ===
                      "ctaButtonComponent" && (
                      <PrimaryButtonWithIcon
                        buttonType="button"
                        name={item.fields.tabContent[0].fields.ctaText}
                        link={
                          item.fields.tabContent[0].fields.buttonLinkExternal
                        }
                        variant={
                          item.fields.tabContent[0].fields.ctaButtonVariety ===
                          "PrimaryDark"
                            ? "dark"
                            : "light"
                        }
                        classes="mb-8"
                      />
                    )}
                  </div>
                );
              })}
            </div>
          )}

          {(isMobile || isTablet) && (
            <div
              className={`${
                border ? "border border-neutral-50 rounded-2xl" : ""
              }  overflow-hidden`}
            >
              <div className={`relative`}>
                <div
                  className={`mobileSelectedTab flex justify-between items-center p-24 ${
                    border ? "border-b" : "border rounded-2xl"
                  } border-neutral-50  ${toggleTabsDD ? "active" : ""}`}
                  onClick={(e) => {
                    toggleMobileTabs(e);
                  }}
                >
                  <div
                    className={`selected flex justify-start items-center gap-12 cursor-pointer`}
                  >
                    {selectedImageUrl && (
                      <Image
                        imageUrl={selectedImageUrl}
                        altText="TabIcon"
                        width="30"
                      />
                    )}
                    <Paragraph content={selectedText} classes="!text-lg" />
                  </div>
                  <div className="">
                    <ArrowUpIcon />
                    <ArrowDownIcon />
                  </div>
                </div>

                <div
                  className={`mobileTabHeading flex flex-col ${
                    border ? "border-b" : ""
                  } border-neutral-50 absolute top-full left-0 bg-white w-full ${
                    toggleTabsDD ? "border rounded-2xl active" : ""
                  }`}
                >
                  <div
                    className={`tabs flex justify-between items-center gap-12 p-24 cursor-pointer ${
                      selectedIndex === 0 ? "bg-neutral-30" : ""
                    }`}
                    onClick={(e) => {
                      onTabSelectMob({ fields: { tabTitle: "All" } }, 0);
                    }}
                  >
                    <div className="flex">
                      <Paragraph content="All" classes="!text-lg ml-16" />
                    </div>
                    {selectedIndex === 0 && (
                      <div className="">
                        <CheckPurpleIcon />
                      </div>
                    )}
                  </div>
                  {content.tabsGroup.map((item, index) => {
                    return (
                      <div
                        className={`tabs flex justify-between items-center gap-12 p-24 cursor-pointer ${
                          selectedIndex === index + 1 ? "bg-neutral-30" : ""
                        }`}
                        onClick={(e) => {
                          onTabSelectMob(item, index + 1);
                        }}
                      >
                        <div className="flex">
                          {item.fields.icon && (
                            <Image
                              imageUrl={item.fields.icon.fields.file.url}
                              altText="TabIcon"
                              width="30"
                            />
                          )}
                          <Paragraph
                            content={item.fields.tabTitle}
                            classes="!text-lg ml-16"
                          />
                        </div>
                        {selectedIndex === index + 1 && (
                          <div className="">
                            <CheckPurpleIcon />
                          </div>
                        )}
                      </div>
                    );
                  })}
                </div>
              </div>
              {content.tabsGroup.map((item, index) => {
                return (
                  <div
                    key={`tabContent-${index}`}
                    className={`tabContent ${border ? "p-24" : "pt-24"} ${
                      selectedText === "All"
                        ? "active"
                        : selectedIndex === index + 1
                        ? "active"
                        : ""
                    }`}
                  >
                    {item.fields.content &&
                      item.fields.content.content.map((textItem, textIndex) => {
                        return (
                          <div key={`key${textIndex}`}>
                            <Paragraph
                              content={textItem.content[0].value}
                              classes="mb-24"
                            />
                          </div>
                        );
                      })}

                    {item.fields.tabContent[0].sys.contentType.sys.id ===
                      "blockInfoFeatureComponent" && (
                      <div className="w-100 layout">
                        <Headings
                          content={item?.fields?.tabTitle}
                          variant="H2"
                          classes="text-purple-100"
                        />
                        {item?.fields?.tabContent.map((item, index) => {
                          return <Listing key={index} content={getListingContent(item)} />;
                        })}
                      </div>
                    )}

                    {item.fields.tabContent[0].sys.contentType.sys.id ===
                      "ctaButtonComponent" && (
                      <PrimaryButtonWithIcon
                        buttonType="button"
                        name={item.fields.tabContent[0].fields.ctaText}
                        link={
                          item.fields.tabContent[0].fields.buttonLinkExternal
                        }
                        variant={
                          item.fields.tabContent[0].fields.ctaButtonVariety ===
                          "PrimaryDark"
                            ? "dark"
                            : "light"
                        }
                        classes="mb-8"
                      />
                    )}
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default TabSelector;
