const FormValidations = () => {

    let validations = []
    validations["successTitle"] = "Thank You"
    validations["successMsg"] = `Your online form submission has been received. We appreciate your time and will action your request as a priority.<br/><br/> Please allow a few days for a response (if required).`
    validations["error"] = "Something went wrong. Please try again."
    validations["whatIsYourEnquiryAbout"] = {
        id: "whatIsYourEnquiryAbout",
        pattern: "",
        helpText: "",
        required: true,
        error: false,
        errorMsg: "",
        requiredMsg: "This field is mandatory",
        validationMsg: ""
    }
    validations["memberNumber"] = {
        id: "memberNumber",
        pattern: /^[0-9]{3,9}$/,
        helpText: "",
        required: false,
        error: false,
        errorMsg: "",
        requiredMsg: "This field is mandatory",
        validationMsg: "Accepts only numbers(0-9),(min: 3, max: 9 characters allowed)"
    }
    validations["fullName"] = {
        id: "fullName",
        pattern: /^[a-zA-Z ]{2,100}$/,
        helpText: "",
        required: true,
        error: false,
        errorMsg: "",
        requiredMsg: "This field is mandatory",
        validationMsg: "Accepts only alphabets and space, (min: 10, max: 100 characters allowed)"
    }
    validations["emailAddress"] = {
        id: "emailAddress",
        pattern: /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/,
        helpText: "",
        required: true,
        error: false,
        errorMsg: "",
        requiredMsg: "This field is mandatory",
        validationMsg: "Invalid email address. Format: xyz@example.com | xyz@example.com.au"
    }
    validations["phoneNumber"] = {
        id: "phoneNumber",
        pattern: /^\+([0-9]{1,2})?([0-9]{10})$/,
        helpText: "",
        required: false,
        error: false,
        errorMsg: "",
        requiredMsg: "This field is mandatory",
        validationMsg: "Accepts only numbers[0-9] and '+' (min: 10, max: 13 characters allowed) Format: +61123456789"
    }
    validations["yourEnquiry"] = {
        id: "yourEnquiry",
        pattern: "",
        helpText: "",
        required: false,
        error: false,
        errorMsg: "",
        requiredMsg: "This field is mandatory",
        validationMsg: ""
    }

    return { validations }
    
}

export { FormValidations }