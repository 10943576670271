import * as React from "react";
import { useEffect, useRef, useState } from "react";
import OutlineButtonSearchIcon from "../../../Generic/Button/Outline/SearchIconButton";
import OutlineButton from "../../../Generic/Button/Outline/Button";
import SecondaryButton from "../../../Generic/Button/Secondary/Button";
import TextButton from "../../../Generic/Button/Text/Button";
import { ReactComponent as SearchIco } from "../../../../Assets/search.svg";
interface Props {
  content?: any;
}

const DesktopMenu = ({ content }: Props) => {
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const searchRef = useRef(null);
  const [query, setQuery] = useState("");
  const inputRef = useRef(null);

  const toggleSearch = () => {
    setIsSearchOpen((prevState) => !prevState);
  };

  useEffect(() => {
    if (isSearchOpen) {
      if (inputRef.current) {
        inputRef.current.focus();
      }
    }
  });

  const handelClickOutside = (event) => {
    if (searchRef.current && !searchRef.current.contains(event.target)) {
      setIsSearchOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handelClickOutside);

    return () => {
      document.removeEventListener("mousedown", handelClickOutside);
    };
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();
    window.location.href = `/search-results?query=${encodeURIComponent(query)}`;
    setIsSearchOpen(false);
  };

  return (
    <div data-testid="desktopmenu" className="md:flex flex-row w-full">
      <div className="flex-1 pl-6 justify-between relative">
        <ul className="headerMenu py-5 ml-12">
          {content?.fields?.navigationSection?.fields?.menus.map(
            (item, index) => (
              <li
                className="m-0 px-16 float-left text-18 font-semibold last:border-l"
                key={`${item?.sys?.id}-${index}`}
              >
                <TextButton
                  variant="light"
                  name={item?.fields?.groupName}
                  classes="text-neutral-10 hover:underline"
                  link={item?.fields?.externelLink}
                />
              </li>
            )
          )}
        </ul>
        <div>
          {isSearchOpen && (
            <div
              ref={searchRef}
              className="left-0 right-0 ml-14 absolute top-0"
            >
              <form onSubmit={handleSubmit}>
                <div className="relative w-full flex py-1 ">
                  <button
                    aria-label="Search submit button"
                    type="submit"
                    disabled={query.length < 3}
                    className="absolute inset-y-0 right-0 p-3 px-16 flex items-center cursor-pointer disabled:opacity-40 disabled:cursor-default"
                  >
                    <SearchIco />
                  </button>
                  <input
                    ref={inputRef}
                    aria-label="Search Text Input"
                    type="text"
                    className="w-full bg-neutral-10 h-14 ps-5 pr-50 rounded-lg rounded-e-none border border-neutral-50 focus:outline-none text-neutral-90 text-lg focus:border-violet-300 transition-colors duration-300"
                    placeholder="Search..."
                    onChange={(e) => setQuery(e.target.value)}
                  />
                </div>
              </form>
            </div>
          )}
        </div>
      </div>
      <div className="flex items-center">
        {!isSearchOpen && (
          <OutlineButtonSearchIcon
            variant="light"
            buttonType="button"
            classes="mr-12"
            onClick={toggleSearch}
          />
        )}
        {isSearchOpen && (
          <OutlineButtonSearchIcon
            variant="dark"
            buttonType="button"
            classes="mr-12"
            onClick={toggleSearch}
          />
        )}
        <OutlineButton
          variant="light"
          name={
            content?.fields?.navigationSection?.fields?.register?.fields
              ?.ctaText
          }
          buttonType="button"
          link={
            content?.fields?.navigationSection?.fields?.register?.fields
              ?.buttonLinkExternal
          }
          classes="mr-12"
        />
        <SecondaryButton
          name={
            content?.fields?.navigationSection?.fields?.login?.fields?.ctaText
          }
          link={
            content?.fields?.navigationSection?.fields?.login?.fields
              ?.buttonLinkExternal
          }
          classes="mr-12"
        />
      </div>
    </div>
  );
};
export default DesktopMenu;
