import * as React from "react";
import { useEffect, useState } from "react";
import Headings from "../../Generic/Heading";
import Paragraph from "../../Generic/Paragraph";
import PrimaryButtonWithIcon from "../../Generic/Button/Primary/ButtonWithIcon";
import TextButtonWithIcon from "../../Generic/Button/Text/ButtonWithIcon";
import Image from "../../Generic/Image";
import MoreEvents from "./MoreEvents";
import EventRegistrationForm from "../EventRegistrationForm";

interface Props {
  content?: any;
  eventDetailsData?: any;
  eventsData?: any;
}

/**
 * Event Details Component
 * @param content - Content from the CMS.
 */
const EventDetails = ({ content, eventDetailsData, eventsData }: Props) => {
  const [moreDates, setMoreDates] = useState([]);
  const [form, setForm] = useState("");
  const [evtDataForm, setEvtDataForm] = useState({});

  const handleForm = (e: any, evtId: string) => {
    e.preventDefault();
    e.stopPropagation();
    setForm(evtId);
  };

  const formatDateDifferentDay = () => {
    return `${new Date(
      eventDetailsData[0]?.fields?.startDate
    ).toLocaleDateString("default", { weekday: "long" })}, ${new Date(
      eventDetailsData[0]?.fields?.startDate
    ).toLocaleDateString("default", { day: "numeric" })} ${new Date(
      eventDetailsData[0]?.fields?.startDate
    ).toLocaleDateString("default", { month: "long" })} ${new Date(
      eventDetailsData[0]?.fields?.startDate
    ).toLocaleTimeString("default", {
      hour12: true,
      hour: "2-digit",
      minute: "2-digit",
    })} - ${new Date(eventDetailsData[0]?.fields?.endDate).toLocaleDateString(
      "default",
      { weekday: "long" }
    )}, ${new Date(eventDetailsData[0]?.fields?.endDate).toLocaleDateString(
      "default",
      { day: "numeric" }
    )} ${new Date(eventDetailsData[0]?.fields?.endDate).toLocaleDateString(
      "default",
      { month: "long" }
    )} ${new Date(eventDetailsData[0]?.fields?.endDate).toLocaleTimeString(
      "default",
      { hour12: true, hour: "2-digit", minute: "2-digit" }
    )}`;
  };

  const formatDateSameDay = () => {
    return `${new Date(
      eventDetailsData[0]?.fields?.startDate
    ).toLocaleDateString("default", { weekday: "long" })}, ${new Date(
      eventDetailsData[0]?.fields?.startDate
    ).toLocaleDateString("default", { day: "numeric" })} ${new Date(
      eventDetailsData[0]?.fields?.startDate
    ).toLocaleDateString("default", { month: "long" })} ${new Date(
      eventDetailsData[0]?.fields?.startDate
    ).toLocaleTimeString("default", {
      hour12: true,
      hour: "2-digit",
      minute: "2-digit",
    })} - ${new Date(eventDetailsData[0]?.fields?.endDate).toLocaleTimeString(
      "default",
      { hour12: true, hour: "2-digit", minute: "2-digit" }
    )}`;
  };

  useEffect(() => {
    if (!form) return;
    const targetElement: any = document.querySelector(".backToEvents");
    const top = targetElement?.offsetTop;
    window.scrollTo({ top: top, behavior: "smooth" });
  }, [form]);

  useEffect(() => {
    if (
      !eventDetailsData ||
      eventDetailsData.length === 0 ||
      eventsData.length === 0
    )
      return;

    const evtForm = {
      ...eventDetailsData[0]?.fields,
      eventDate:
        new Date(eventDetailsData[0]?.fields?.startDate).toLocaleDateString(
          "default",
          { day: "numeric" }
        ) !==
        new Date(eventDetailsData[0]?.fields?.endDate).toLocaleDateString(
          "default",
          { day: "numeric" }
        )
          ? formatDateDifferentDay()
          : formatDateSameDay(),
      eventDescription:
        document.getElementsByClassName("evt-desc")[0].textContent,
    };

    setEvtDataForm(evtForm);
    const startDate = eventDetailsData[0]?.fields?.startDate;

    const moreDates = eventsData?.filter(
      (item) =>
        item !== eventDetailsData[0] && item?.fields?.startDate === startDate
    );
    if (moreDates.length > 0) {
      setMoreDates(moreDates);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [content, eventsData, eventDetailsData]);

  return (
    <>
      <div
        id={eventDetailsData[0]?.fields?.eventId}
        className={`layoutSpacing w-100 bg-white`}
      >
        <div className="layout">
          <div className="backToEvents sectionBottomSpacing">
            <TextButtonWithIcon
              variant="dark"
              name="Back to all events"
              underline={true}
              iconPosition="start"
              arrowDirection="left"
              classes="!text-black cursor-pointer"
              link={window.location.pathname}
            />
          </div>
          {form === "" && (
            <div className="sectionBottomSpacing">
              <div className="flex flex-col lg:flex-row gap-32">
                <div className={`w-100 xl:w-75`}>
                  <Headings
                    variant="H2"
                    content={`${eventDetailsData[0]?.fields?.eventTopic} - ${eventDetailsData[0]?.fields?.title}`}
                    classes="text-purple-100"
                  />
                  <Paragraph
                    content={eventDetailsData[0]?.fields?.eventDescription}
                    classes="w-100 xl:w-75 evt-desc"
                  />
                </div>
                <div className={`grow shrink-0 lg:w-25`}>
                  <div className="flex flex-col gap-32 grow">
                    <div className="flex flex-col items-start gap-8">
                      <div className="flex items-center gap-8">
                        <Image
                          imageUrl={
                            eventDetailsData[0]?.fields?.fundTypeIcon?.fields
                              ?.file?.url
                          }
                          altText={
                            eventDetailsData[0]?.fields?.fundTypeIcon?.fields
                              ?.file?.title
                          }
                        />
                        <Paragraph
                          content={eventDetailsData[0]?.fields?.fundTypeLabel}
                          classes="!text-sm"
                        />
                      </div>
                      <div className="flex pl-24">
                        <Paragraph
                          content={eventDetailsData[0]?.fields?.fundTypeValue}
                        />
                      </div>
                    </div>
                    {/* Hiding as per client request */}
                    {/* <div className="flex flex-col items-start gap-8">
                      <div className="flex items-center gap-8">
                        <Image
                          imageUrl={
                            eventDetailsData[0]?.fields?.presenterIcon?.fields
                              ?.file?.url
                          }
                          altText={
                            eventDetailsData[0]?.fields?.presenterIcon?.fields
                              ?.file?.title
                          }
                        />
                        <Paragraph
                          content={eventDetailsData[0]?.fields?.presenterLabel}
                          classes="!text-sm"
                        />
                      </div>
                      <div className="flex pl-24">
                        <Paragraph
                          content={
                            eventDetailsData[0]?.fields?.eventPresenter1?.fields
                              ?.fullName
                          }
                        />
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>
              <div className="flex pt-32">
                <div
                  className={`w-100 lg:w-75 rounded-2xl overflow-hidden hover:border-purple-100 p-24 md:p-32 bg-purple-10`}
                >
                  <div className="flex flex-col gap-32 grow">
                    <div className="flex flex-col items-start gap-8">
                      <div className="flex items-center gap-8">
                        <Image
                          imageUrl={
                            eventDetailsData[0]?.fields?.locationIcon?.fields
                              ?.file?.url
                          }
                          altText={
                            eventDetailsData[0]?.fields?.locationIcon?.fields
                              ?.file?.title
                          }
                        />
                        <Paragraph content="Location" classes="!text-sm" />
                      </div>
                      <div className="flex pl-24">
                        <Paragraph
                          content={
                            eventDetailsData[0]?.fields?.locationPhysicalAddress ? eventDetailsData[0]?.fields?.locationPhysicalAddress : 'Online'
                          }
                        />
                      </div>
                    </div>
                    <div className="flex flex-col items-start gap-8">
                      <div className="flex items-center gap-8">
                        <Image
                          imageUrl={
                            eventDetailsData[0]?.fields?.eventCalendarIcon
                              ?.fields?.file?.url
                          }
                          altText={
                            eventDetailsData[0]?.fields?.eventCalendarIcon
                              ?.fields?.file?.title
                          }
                        />
                        <Paragraph content="Date" classes="!text-sm" />
                      </div>
                      <div className="flex pl-24">
                        {/* Start date and end is different day */}
                        {new Date(
                          eventDetailsData[0]?.fields?.startDate
                        ).toLocaleDateString("default", { day: "numeric" }) !==
                          new Date(
                            eventDetailsData[0]?.fields?.endDate
                          ).toLocaleDateString("default", {
                            day: "numeric",
                          }) && (
                          <Paragraph content={formatDateDifferentDay()} />
                        )}
                        {/* Start date and end date is same day */}
                        {new Date(
                          eventDetailsData[0]?.fields?.startDate
                        ).toLocaleDateString("default", { day: "numeric" }) ===
                          new Date(
                            eventDetailsData[0]?.fields?.endDate
                          ).toLocaleDateString("default", {
                            day: "numeric",
                          }) && <Paragraph content={formatDateSameDay()} />}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="pt-32">
                {eventDetailsData[0]?.fields?.onlineEventLink && (
                  <PrimaryButtonWithIcon
                    name="Register"
                    variant="dark"
                    link={eventDetailsData[0]?.fields?.onlineEventLink}
                    target={
                      eventDetailsData[0]?.fields?.onlineEventLink
                        ? "_blank"
                        : "_self"
                    }
                  />
                )}
                {!eventDetailsData[0]?.fields?.onlineEventLink && (
                  <div
                    onClick={(e) =>
                      handleForm(e, eventDetailsData[0]?.fields?.eventId)
                    }
                  >
                    <PrimaryButtonWithIcon
                      name="Register"
                      variant="dark"
                      link="/"
                      buttonType="submit"
                    />
                  </div>
                )}
              </div>
            </div>
          )}
          {form !== "" && (
            <div
              id={form}
              className="evtForm"
              data-desc={eventDetailsData[0]?.fields?.eventDescription}
            >
              <EventRegistrationForm eventData={evtDataForm} />
            </div>
          )}
        </div>
      </div>
      {moreDates.length > 0 && (
        <MoreEvents
          background="purple"
          cardBackground="white"
          border={true}
          columnLayout="3"
          content={moreDates}
        />
      )}
    </>
  );
};

export default EventDetails;
