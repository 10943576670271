const FormValidations = () => {

    let validations = []
    validations["successTitle"] = "Thank You"
    validations["successMsg"] = `Your online form submission has been received. We appreciate your time and will action your request as a priority.<br/><br/> Please allow a few days for a response (if required).`
    validations["error"] = "Something went wrong. Please try again."
    validations["consent"] = {
        text: "I consent to providing ESSSuper with my retirement date subject to the Privacy and Data Protection Act 2014 for the purposes of providing me with information about retirement and superannuation. For more information about how ESSSuper handles your personal information, please see the ESSSuper Privacy Policy and Privacy Collection Statement available at esssuper.com.au*",
        error: true,
        requiredMsg: "This field is mandatory",
        validationMsg: "This field is mandatory",
    }
    validations["haveYouAlreadyRetired"] = {
        id: "haveYouAlreadyRetired",
        pattern: "",
        helpText: "",
        required: true,
        error: false,
        errorMsg: "",
        requiredMsg: "This field is mandatory",
        validationMsg: "",
        format: "date"
    }
    validations["aimtoretire"] = {
        id: "aimtoretire",
        pattern: "",
        helpText: "",
        required: false,
        error: false,
        errorMsg: "",
        requiredMsg: "This field is mandatory",
        validationMsg: "Aim to retire date should not be older than the today",
        format: "date"
    }
    validations["ageRetirement"] = {
        id: "ageRetirement",
        pattern: /^[0-9]{2}$/,
        helpText: "",
        required: true,
        error: false,
        errorMsg: "",
        requiredMsg: "This field is mandatory",
        validationMsg: "Accepts only numbers(0-9),(Only 2 digits allowed)"
    }
    validations["memberNumber"] = {
        id: "memberNumber",
        pattern: /^[0-9]{3,9}$/,
        helpText: "",
        required: true,
        error: false,
        errorMsg: "",
        requiredMsg: "This field is mandatory",
        validationMsg: "Accepts only numbers(0-9),(min: 3, max: 9 characters allowed)"
    }
    validations["fullName"] = {
        id: "fullName",
        pattern: /^[a-zA-Z ]{2,100}$/,
        helpText: "",
        required: true,
        error: false,
        errorMsg: "",
        requiredMsg: "This field is mandatory",
        validationMsg: "Accepts only alphabets and space, (min: 10, max: 100 characters allowed)"
    }
    validations["dob"] = {
        id: "dob",
        pattern: "",
        helpText: "",
        required: true,
        error: false,
        errorMsg: "",
        requiredMsg: "This field is mandatory",
        validationMsg: "Date of birth should be older than the today",
        format: "date"
    }
    validations["emailAddressTitle"] = {
        id: "emailAddressTitle",
        pattern: /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/,
        helpText: "",
        required: true,
        error: false,
        errorMsg: "",
        requiredMsg: "This field is mandatory",
        validationMsg: "Invalid email address. Format: xyz@example.com | xyz@example.com.au"
    }
    validations["phoneNumber"] = {
        id: "phoneNumber",
        pattern: /^\+([0-9]{1,2})?([0-9]{10})$/,
        helpText: "",
        required: false,
        error: false,
        errorMsg: "",
        requiredMsg: "This field is mandatory",
        validationMsg: "Accepts only numbers[0-9] and '+' (min: 10, max: 13 characters allowed) Format: +61123456789"
    }
    validations["address"] = {
        id: "address",
        pattern: "",
        helpText: "",
        required: true,
        error: false,
        errorMsg: "",
        requiredMsg: "This field is mandatory",
        validationMsg: ""
    }

    return { validations }
    
}

export { FormValidations }