import React, { useEffect, useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  ArcElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar, Line, Pie, Doughnut } from "react-chartjs-2";
import { colorSet } from "../ChartsContainer/index.data";

// Register Chart Component
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  ArcElement,
  Title,
  Tooltip,
  Legend
);

interface props {
  type?: string;
  data?: object;
  isPercentageValue?: boolean;
}

const Charts = ({ type, data, isPercentageValue }: props) => {
  const [options, setOptions] = useState({});
  useEffect(() => {
    if (type === "doughnut" || type === "pie") {
      setOptions({
        responsive: true,
        maintainAspectRatio: true,
        cutout: "75%",
        plugins: {
          legend: {
            display: false,
            position: "right",
            labels: {
              padding: 15,
              usePointStyle: true,
              // generateLabels: function (chart) {
              // console.log('chart.data.datasets', chart.data.datasets)
              // return ` ${chart.data.datasets[0].label} `;
              // },
            },
          },
          tooltip: {
            callbacks: {
              label: function (context) {
                // const label = context.label || "";
                const value = context.raw;
                return isPercentageValue ? ` ${value}%` : value;
              },
              labelPointStyle: function () {
                return {
                  pointStyle: "circle",
                };
              },
            },
          },
        },
        scales: {
          x: {
            display: false,
          },
          y: {
            display: false,
          },
        },
      });
    } else {
      setOptions({
        responsive: true,
        layout: {
          padding: {
            right: 20,
            left: 20,
          },
        },
        plugins: {
          legend: {
            position: "top",
            // align: "start",
            labels: {
              padding: 15,
              usePointStyle: true,
              // generateLabels: function (chart) {
              // console.log('chart.data.datasets', chart.data.datasets)
              // return ` ${chart.data.datasets[0].label} `;
              // },
            },
          },
          title: { display: false },
          tooltip: {
            callbacks: {
              label: function (context) {
                // const label = context.label || "";
                const value = context.raw;
                return isPercentageValue ? ` ${value}%` : value;
              },
              labelPointStyle: function () {
                return {
                  pointStyle: "circle",
                };
              },
            },
          },
        },
        scales: {
          x: {
            grid: {
              display: false,
            },
            ticks: {
              color: "#000",
              font: {
                size: 16,
              },
            },
          },
          y: {
            ticks: {
              color: "#000",
              font: {
                size: 16,
              },
              callback: function (value) {
                return isPercentageValue ? value + "%" : value;
              },
            },
          },
        },
      });
    }
  }, [type]);

  const mapChartData = (chart) => {
    if (!chart || !chart.datasets || !Array.isArray(chart.datasets)) {
      return {
        labels: [],
        datasets: [],
      };
    }

    return {
      labels: chart.labels,
      datasets: chart.datasets.map((item, index) => {
        return {
          label: item.label,
          data: item.data.map((value) => {
            return parseFloat(value.toString().replace("%", ""));
          }),
          borderColor:
            type === "doughnut" || type === "pie" ? colorSet : colorSet[index],
          backgroundColor:
            type === "doughnut" || type === "pie" ? colorSet : colorSet[index],
          barThickness: 30,
          minBarThickness: 5,
          maxBarThickness: 50,
          pointRadius: 2,
          pointHoverRadius: 4,
        };
      }),
    };
  };

  if (mapChartData(data).datasets.length) {
    switch (type) {
      case "bar":
        return <Bar data={mapChartData(data)} options={options} />;
      case "line":
        return <Line data={mapChartData(data)} options={options} />;
      case "pie":
        return <Pie data={mapChartData(data)} options={options} />;
      case "doughnut":
        return <Doughnut data={mapChartData(data)} options={options} />;
      default:
        return <p>Invalid Charts</p>;
    }
  }
};

export default Charts;
