import * as React from "react"
import Headings from "../../Generic/Heading"
import TextButtonWithIcon from "../../Generic/Button/Text/ButtonWithIcon"
import { ReactComponent as ArrowUpIcon } from '../../../Assets/arrowUp.svg'
import { ReactComponent as ArrowDownIcon } from '../../../Assets/arrowDown.svg'
import { useDeviceDetector } from "../../../Hooks/useDeviceDetector"
import './style.css'

interface Props {
    content?: any
}
/**
 * Quick Links Component
 */
const QuickLinksBlock = ({content}: Props) => {
    const { isMobile } = useDeviceDetector()

    const toggleQuickLinks = (e: any) => {
        e.stopPropagation()
        if(isMobile) {
            const parentEle = e.target.closest('.container')
            parentEle.classList.toggle('active')
        }
    }

    const generateLink = (text, link) => {
        return `<a href=${link}>${text}</a>`
    }

    return (<>
        <div className="layoutSpacing">
            <div data-testid="quickLinks" className="w-100 sectionBottomSpacing layout">
                <div className={`flex flex-wrap quicklinks ${isMobile ? 'mobile' : ''} gap-24`}>
                    {content?.features?.map((item, index) =>
                        <div key={`${item?.fields?.headline}-${index}`} className="container flex flex-col w-full sm:w-[calc(50%-12px)] lg:w-[calc(33%-18px)] xl:w-[calc(25%-18px)]">
                            <div className="title flex justify-between items-cs pb-12 mb-16 border-b-4 border-orange-100 text-purple-100" onClick={(e) => {toggleQuickLinks(e)}}>
                                {item?.fields?.link && <Headings content={generateLink(item?.fields?.headline, item?.fields?.link)} variant="H4" classes="text-purple-100 pr-12" />}
                                {!item?.fields?.link && <Headings content={item?.fields?.headline} variant="H4" classes="text-purple-100 pr-12" />}
                                {isMobile && item?.fields?.linksgroup?.length > 0 && (<div key={`arrow-icon-${index}`}>
                                    <ArrowUpIcon />
                                    <ArrowDownIcon />
                                    </div>)
                                }
                            </div>
                            <div className={`list flex flex-col items-start w-100 mb-16`}>
                                {item?.fields?.linksgroup?.map((links) =>
                                    <div key={`${links?.fields?.linkName}-${index}`}>
                                        <TextButtonWithIcon name={links?.fields?.linkName} link={links?.fields?.externalLink ? links?.fields?.externalLink : links?.fields?.links} variant="dark" iconPosition="start" fontColor="text-black" underline={true} classes="mb-8 sub-links" />
                                    </div>
                                )}
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
        </>
    )
}

export default QuickLinksBlock