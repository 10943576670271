import React, { useEffect, useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  ArcElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";

// Register Chart Component
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  ArcElement,
  Title,
  Tooltip,
  Legend
);

const Chart = (content) => {
  const [data, setData] = useState([])
  const colorSet = [
    "#49247D",
    "#6D5097",
    "#927CB1",
    "#EE7B00",
    "#F19533",
    "#F5B066",
    "#159DA4",
    "#44B1B6",
    "#73C4C8",
    "#E00000",
    "#A10000",
    "#008243",
    "#005E30",
  ];

  const options = {
    responsive: true,
    interaction: {
      mode: "index" as const,
      intersect: false,
    },
    stacked: false,
    plugins: {
      legend: {
        labels: {
          // This more specific font property overrides the global property
          font: {
            size: 16,
            family: "'Manrope', sans-serif",
          },
          display: false,
        },
      },
      title: {
        display: false,
        text: "Daily Unit Prices",
        font: {
          size: 16,
          family: "'Manrope', sans-serif",
        },
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
        ticks: {
          color: "#000",
          font: {
            size: 16,
            family: "'Manrope', sans-serif",
          },
        },
      },
      y: {
        grid: {
          display: true,
        },
        ticks: {
          color: "#000",
          font: {
            size: 16,
            family: "'Manrope', sans-serif",
          },
        },
      },
    },
  };

  useEffect(() => {

    const dataSet = [];
    const serializeData = {};
    
    content?.content[0]?.rowValues?.map((item) => {
      for (const v in item) {
        if (!serializeData[v] || serializeData[v].length === 0) {
          serializeData[v] = [];
        }
        serializeData[v].unshift(item[v]);
      }
    });
    
    Object.keys(serializeData).forEach((key, index) => {
      if (key !== "Date") {
        const obj = {
          label: key,
          data: serializeData[key],
          borderColor: colorSet[index],
          backgroundColor: colorSet[index],
        };
  
        dataSet.push(obj);
      }
    });
  
    const labels = serializeData["Date"]?.map((item) => {
      const date = new Date(item);
      const unitDate =
        date.toLocaleDateString("default", { day: "numeric" }) +
        " " +
        date.toLocaleDateString("default", { month: "short", year: "numeric" });
      return unitDate;
    });
    const data1 = {
      labels,
      datasets: dataSet,
    }
    setData([data1])

  }, [content])

  return (
    <>
      {data.length > 0 && <Line data={data[0]} options={options} />}
    </>
  )
};

export default Chart;
