import * as React from "react";
import Paragraph from "../../Generic/Paragraph";
import Headings from "../../Generic/Heading";
import { ButtonWrapper, RenderButton } from "../../Generic/Button/RenderButton";
import Image from "../../Generic/Image";
import Video from "../../Generic/Video";

interface Props {
  content?: any;
  background?: string;
  textAlignment?: boolean;
  cardBackground?: string;
  marginBottom?: boolean;
}

/**
 * Callout Component
 * @param content - Content from the CMS.
 * @param cardBackground (optional) - Background of the component. Expected values: "white" | "teal" | "lightPurple" | "orange", Default: "transparent"
 * @param background (optional) - Background color of the component. Expected values: "white" | "purple" | "lightPurple" | "orange" | "teal", Default: "white"
 * @param textAlignment (optional) - Text Alignment of the component. Expected values: true | false, Default: "center"
 * @param marginBottom (optional) - Bottom margin of the component. Expected values: true | false, Default: true
 */
const Callout = ({
  content,
  background = "white",
  cardBackground = "transparent",
  textAlignment,
  marginBottom = true,
}: Props) => {
  background = background.toLowerCase();
  cardBackground = cardBackground.toLowerCase();
  const bgColor =
    background === "lightpurple"
      ? "bg-purple-10"
      : background === "orange"
      ? "bg-orange-10"
      : background === "teal"
      ? "bg-teal-10"
      : "bg-white";
  const bgCardColor =
    cardBackground === "lightpurple"
      ? "bg-purple-10"
      : cardBackground === "orange"
      ? "bg-orange-10"
      : cardBackground === "teal"
      ? "bg-teal-10"
      : "bg-white";
  const columns =
    content.cardLayout === "3"
      ? "xl:w-[32%]"
      : content.cardLayout === "4"
      ? "xl:w-[23.6%]"
      : "xl:w-[49%]";
  const layout = `md:w-[48.25%] lg:w-[48.7%] ${columns} md:max-w-[50%]`;
  const textAlign =
    textAlignment === true
      ? "left"
      : textAlignment === false
      ? "right"
      : "center";

  const isIconIncluded = (str) => {
    return str && str.toLowerCase().includes("icon");
  };

  return (
    <>
      <div data-testid="callout" className={`Callout layoutSpacing ${bgColor}`}>
        <div
          className={`w-full ${
            bgColor !== "bg-white" ? "sectionSpacing" : "sectionBottomSpacing"
          } 
        ${
          bgColor !== "bg-white" && marginBottom ? "sectionBottomMargin" : ""
        } layout } layout`}
        >
          {(content?.headline || content?.description) && (
            <div className="w-full">
              {content?.headline && (
                <Headings
                  content={content?.headline}
                  variant="H2"
                  classes={`text-purple-100 ${
                    content?.description ? "" : "!pb-24"
                  }`}
                />
              )}
              {content?.headline !== "Phone" && content?.description && (
                <Paragraph content={content?.description} classes="pb-16" />
              )}
            </div>
          )}
          <div className="flex flex-wrap justify-start gap-16">
            {content?.features?.map((item) => (
              <>
                {item?.sys?.contentType?.sys?.id ===
                  "blockInfoFeatureComponent" && (
                  <div
                    key={item?.fields?.headline}
                    className={`flex flex-col ${
                      bgCardColor === "bg-white"
                        ? "bg-transparent"
                        : bgCardColor
                    } w-full ${content?.features.length > 1 ? layout : ""}`}
                  >
                    {item?.fields?.contentImage?.fields?.file?.url &&
                      !isIconIncluded(
                        item?.fields?.contentImage?.fields?.title
                      ) &&
                      !isIconIncluded(
                        item?.fields?.contentImage?.fields?.description
                      ) && (
                        <div>
                          <Image
                            imageUrl={
                              item?.fields?.contentImage?.fields?.file?.url
                            }
                            altText={item?.fields?.contentImage?.fields?.title}
                          />
                        </div>
                      )}
                    {item?.fields?.contentImage?.fields?.file?.url &&
                      (isIconIncluded(
                        item?.fields?.contentImage?.fields?.title
                      ) ||
                        isIconIncluded(
                          item?.fields?.contentImage?.fields?.description
                        )) && (
                        <div className="pb-16 md:pb-16">
                          <Image
                            imageUrl={
                              item?.fields?.contentImage?.fields?.file?.url
                            }
                            altText={item?.fields?.contentImage?.fields?.title}
                            width="56"
                          />
                        </div>
                      )}
                    {item?.fields?.headline &&
                      item?.fields?.headline !== null && (
                        <div
                          className={`${
                            !item?.description &&
                            item?.description === null &&
                            "flex-grow"
                          } pr-16 pb-12 md:pb-12 text-${textAlign}`}
                        >
                          <Headings
                            variant="H4"
                            content={item?.fields?.headline}
                            classes={`!text-80 text-purple-100`}
                          />
                        </div>
                      )}
                    {item?.fields?.subHeadline &&
                      item?.fields?.subHeadline !== null && (
                        <div
                          className={`${
                            !item?.description && item?.description === null
                              ? "flex-grow"
                              : ""
                          } pr-16 pb-12 md:pb-12 text-${textAlign}`}
                        >
                          <Headings
                            variant={"H4"} // Condition Added to Match the Figma Design, It could be modified based on Business demand.
                            content={item?.fields?.subHeadline}
                            classes={`text-purple-100`}
                          />
                        </div>
                      )}
                    {item?.fields?.contentDescription &&
                      item?.fields?.contentDescription !== null && (
                        <>
                          <div className={`flex-grow pr-16 text-${textAlign}`}>
                            <Paragraph
                              content={item?.fields?.contentDescription}
                            />
                          </div>
                        </>
                      )}
                    {item?.fields?.video && 
                      <div className={`pr-16 pb-12 md:pb-12 text-${textAlign}`}>
                        <Video videoUrl={item?.fields?.video} classes={`${item?.fields?.caption ? 'mb-16' : ''}`} caption={item?.fields?.caption} />
                      </div>
                    }
                    {item?.fields?.contentCtaLinkToPage &&
                      item?.fields?.contentCtaLinkToPage !== null && (
                        <div className={`pr-16 pb-16 text-${textAlign} pt-24`}>
                          <RenderButton
                            item={item?.fields?.contentCtaLinkToPage[0]}
                          />
                        </div>
                      )}
                  </div>
                )}
              </>
            ))}
            {content?.assets?.map((item) => (
              <>
                {item?.fields?.file?.contentType.includes("image") && (
                  <Image
                    imageUrl={item?.fields?.file?.url}
                    altText={item?.fields?.description}
                    className={`${
                      /\d/.test(item?.fields?.title) ? "" : "mb-16"
                    }`}
                    caption={/\d/.test(item?.fields?.title) ? "" : item?.fields?.title}
                  />
                )}
                {item?.fields?.file?.contentType.includes("video") && (
                  <Video videoUrl={item?.fields?.file?.url} />
                )}
              </>
            ))}
          </div>

          <div className="flex justify-center">
            <ButtonWrapper content={content} columns={columns} />
          </div>

          {content?.headline === "Phone" && content?.description && (
            <Paragraph content={content?.description} classes="" />
          )}
        </div>
      </div>
    </>
  );
};

export default Callout;
