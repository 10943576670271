import * as React from "react"
import { useEffect, useState } from "react"
import Paragraph from "../../Generic/Paragraph"
import './style.css'
import PrimaryButton from "../../Generic/Button/Primary/Button"
import Headings from "../../Generic/Heading"
import { useIdleTimer } from "react-idle-timer"

interface Props {
    content?: any
    background?: string
}

/**
 * Modal Component
 * @param content - Content from the CMS.
 * @param background (optional) - Background color of the component. Expected values: "white" | "purple" | "orange" | "lightPurple" , Default: "white"
 */
const Modal = ({content, background = 'white'}: Props) => {
    const [modal, setModal] = useState(false)
  
    const onIdle = () => {
      setModal(true)
    }
    
    useIdleTimer({ 
      onIdle,
      timeout: Number(process.env.REACT_APP_MODAL_TIME),
      throttle: 500 
    })

    background = background.toLowerCase()
    const bgColor = background === "purple" ? "bg-purple-100" : background === "orange" ? "bg-orange-10" : background === "teal" ? "bg-teal-10" : background === "lightpurple" ? "bg-purple-10" : "bg-white"
    
    const handleClose = (e) => {
        e.preventDefault()
        e.stopPropagation()
        window.location.reload()
    }

    useEffect(() => {
        if (!modal) return
        document.querySelectorAll('.customForms').forEach((item: HTMLFormElement) => item?.reset())
        document.querySelector('html').classList.add('overflow-hidden')
    }, [modal])
    
    return (<>
        <div data-testid="modal" className={`Modal flex justify-center items-center w-full h-full fixed top-0 left-0 z-10 ${modal ? '' : 'hidden'}`}>
            <div className={`absolute flex flex-col layout ${bgColor} rounded-lg overflow-hidden min-w-[30%]`}>
                <div className="border-b border-b-neutral-50 p-24">
                    <Headings variant="H4" content="Session Expired" classes="text-purple-100 !pb-0" />
                </div>
                <div className="p-24">
                    <Paragraph content={"Your session has expired, refreshing the page..."} classes="!text-base" />
                </div>
                <div className="flex justify-center p-24" onClick={(e) => {handleClose(e)}}>
                    <PrimaryButton name="Ok" variant="dark" classes="!text-base px-24 py-8" buttonType='submit' />
                </div>
            </div>
        </div>
    </>)
}

export default Modal