import * as React from "react";
import PrimaryButtonWithIcon from "../../Generic/Button/Primary/ButtonWithIcon";
import Headings from "../../Generic/Heading";
import Paragraph from "../../Generic/Paragraph";
import "./style.css";

interface Props {
  content: any;
}
/**
 * Hero Banner Component
 * @param content - Content from the CMS.
 */

const HeroBanner = ({ content }: Props) => {
  return (
    <div data-testid="heroBanner" className="w-full sectionBottomSpacing">
      <section className="w-full">
        <div className="w-100 relative bg-primary-bannerBG bannerContainer lg:flex">
          <div
            data-testid="heroBannerImage"
            className="relative w-100 h-250 lg:w-75 lg:ml-auto imageContainer"
          >
            <img
              src={content?.bannerImage?.fields?.file?.url}
              alt={content?.bannerImage?.fields?.title}
              className="w-full  h-full  md:object-cover"
              role="presentation"
            />
            <div className="imageBlur"></div>
          </div>
          <div
            data-testid="heroBannerContent"
            className="text-neutral-10 -mt-80 lg:mt-0 lg:absolute lg:top-16 xl:top-72 w-full layoutSpacing"
          >
            <div className="relative w-full layout">
              <div className="lg:w-50 xl:w-40">
                <Headings
                  variant="H1"
                  content={content?.headline}
                  classes="text-white mt-16"
                />
                <Paragraph classes="mt-16 mb-16" content={content?.subHeadline} />
                <Paragraph classes="mt-16 mb-16" content={content?.bodyText} />
                <div className="mt-40 pb-40">
                  <PrimaryButtonWithIcon
                    variant="light"
                    name={content?.ctaText}
                    link={content?.targetPage?.fields?.slug}
                    buttonType="button"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
export default HeroBanner;
