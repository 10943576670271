export const colorSet = [
  "#159DA4",
  "#49247D",
  "#A59D95",
  "#EE7B00",
  "#48CAD0",
  "#FCB457",
  "#927CB1",
  "#182E57",
  "#368573",
  "#6FC7B6",
  "#182E57",
  "#EE7B00",
  "#FFC7AE",
  "#48CAD0",
  "#FCB457",
];

export const mockContent = {
  referenceName: [
    {
      fields: {
        chartDetails: {
          content: [
            {
              nodeType: 'table',
              content: [
                { // Mock table data structure
                  content: [
                    { content: [{ nodeType: 'text', value: 'Chart Name' }] }, // Chart Name
                    { content: [{ nodeType: 'text', value: 'Jan' }, { nodeType: 'text', value: 'Feb' }] }, // Labels
                  ],
                },
                {
                  content: [
                    { content: [{ nodeType: 'text', value: 'Chart Data' }] }, // Data Name
                    { content: [{ nodeType: 'text', value: '50%' }, { nodeType: 'text', value: '60%' }] }, // Data
                  ],
                },
              ],
            },
          ],
        },
      },
    },
  ],
  chartType: 'bar',
};

export const mockContentWithYearData = {
  ...mockContent,
  referenceName: [
    {
      fields: {
        chartDetails: {
          content: [
            {
              nodeType: 'table',
              content: [
                { // Mock table data structure
                  content: [
                    { content: [{ nodeType: 'text', value: 'Chart Name' }] }, // Chart Name
                    { content: [{ nodeType: 'text', value: '2021' }, { nodeType: 'text', value: '2022' }] }, // Labels
                  ],
                },
                {
                  content: [
                    { content: [{ nodeType: 'text', value: 'Revenue' }] }, // Data Name
                    { content: [{ nodeType: 'text', value: '100%' }, { nodeType: 'text', value: '90%' }] }, // Data
                  ],
                },
              ],
            },
          ],
        },
      },
    },
  ],
};