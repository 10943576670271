import * as React from "react"
import Richtext from "../../Templates/RichText"

interface Props {
    content: any
    classes?: string
}

/**
 * Paragraph Component
 * @param content (optional) - Text of the paragraph.
 * @param classes (optional) - Tailwindcss classes to customize it.
 */
const Paragraph = ({content, classes}: Props) => {

    return (<>
        {!content?.nodeType &&
           <p data-testid="paragraph"  className={`text-container text-md ${classes ? classes : ''}`} dangerouslySetInnerHTML={{__html: content}}></p>
        }
        {content?.nodeType &&
            <Richtext content={content} classes={classes} />
        }
        </>
    )
}

export default Paragraph