import * as React from "react";
import Paragraph from "../Paragraph";

interface Props {
  videoUrl: string
  classes?: string
  caption?: string
}

/**
 * Video Component
 * @param videoUrl - URL of the video.
 */

const Video = ({ videoUrl, caption, classes }: Props) => {
  const extractVideoId = (url) => {
    const regex =
      /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^/]+\/.+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([^"&?/\s]{11})/;
    const match = url.match(regex);
    return match ? match[1] : null;
  };

  return (
    <>
      <div className="w-full layout">
        <div data-testid="video" className={"relative w-full"}>
          <div className={`relative video-responsive aspect-video ${classes ? classes : ''}`}>
            <iframe
              width="560"
              height="315"
              src={`https://www.youtube.com/embed/${extractVideoId(videoUrl)}`}
              title="YouTube video"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              className="absolute w-full h-full left-0 top-0"
            ></iframe>
          </div>
          {caption && <Paragraph content={caption} classes={`!text-base text-neutral-90 mb-16 ${caption ? 'mt-16' : ''}`} />}
        </div>
      </div>
    </>
  );
};

export default Video;
