import * as React from "react";
import Headings from "../../Generic/Heading";
import Paragraph from "../../Generic/Paragraph";
import "./style.css";
import { ButtonWrapper } from "../../Generic/Button/RenderButton";
import PageNotFound from "../PageNotFound";
import Video from "../../Generic/Video";
import Image from "../../Generic/Image";
interface Props {
  content?: any;
  background?: string;
  marginBottom?: boolean;
}

/**
 * TextBlock Component
 * @param content - Content from the CMS.
 * @param background (optional) - Background color of the component. Expected values: "white" | "purple" | "orange" | "lightPurple" , Default: "white"
 */
const TextBlock = ({
  content,
  background = "white",
  marginBottom = true,
}: Props) => {
  background = background.toLowerCase();
  const columns =
    content?.cardLayout === "3"
      ? "xl:w-[32%]"
      : content?.cardLayout === "4"
      ? "xl:w-[23%]"
      : "xl:w-[49%]";
  const bgColor =
    background === "purple"
      ? "bg-purple-100"
      : background === "orange"
      ? "bg-orange-10"
      : background === "teal"
      ? "bg-teal-10"
      : background === "lightpurple"
      ? "bg-purple-10"
      : "bg-white";
  return (
    <>
      {content?.componentName !== "404 page not found" ? (
        <div className="textBlock">
          <div data-testid="textBlock" className={`layoutSpacing ${bgColor}`}>
            <div
              className={`w-full ${
                bgColor !== "bg-white"
                  ? "sectionSpacing"
                  : "sectionBottomSpacing"
              } 
              ${
                bgColor !== "bg-white" && marginBottom
                  ? "sectionBottomMargin"
                  : ""
              } layout`}
            >
              <div id={content?.headline}>
                <div
                  className={`flex flex-col ${
                    content?.texColtLayout && " xl:flex-row gap-24"
                  }`}
                >
                  {content?.headline && (
                    <Headings
                      variant="H2"
                      content={content?.headline}
                      classes={`text-purple-100 ${
                        !content?.texColtLayout && "w-100 md:w-75 xl:w-60"
                      } ${
                        content?.subheadline ||
                        content?.contentDescription ||
                        content?.bodyText ||
                        content?.description ||
                        content?.richText
                          ? "!pb-24"
                          : "!pb-0"
                      }`}
                    />
                  )}
                  {content?.subheadline && (
                    <Headings
                      variant="H3"
                      content={content?.subheadline}
                      classes={`text-purple-100 ${
                        !content?.texColtLayout && "w-100 md:w-75 xl:w-60"
                      }`}
                    />
                  )}
                  <div>
                    <Paragraph
                      classes={`${
                        !content?.texColtLayout && "tableLayoutForPara"
                      }`}
                      content={
                        content?.contentDescription ||
                        content?.bodyText ||
                        content?.description ||
                        content?.richText
                      }
                    />
                    {content?.texColtLayout && (
                      <ButtonWrapper content={content} columns={columns} />
                    )}
                  </div>
                </div>
                {!content?.texColtLayout && (
                  <ButtonWrapper content={content} columns={columns} />
                )}
              </div>
              {content?.assets?.map((item, index) => (
                <div key={`${item?.fields?.title}-${index}`}>
                  {item?.fields?.file?.contentType.includes("image") && (
                    <>
                      {item?.fields?.title !== "Map Image" && (
                        <div className={`w-100 md:w-75 xl:w-60`}>
                          <Image
                            imageUrl={item?.fields?.file?.url}
                            altText={item?.fields?.description}
                            className={`${
                              /\d/.test(item?.fields?.title) ? "" : "mb-16"
                            }`}
                            caption={/\d/.test(item?.fields?.title) ? "" : item?.fields?.title}
                          />
                        </div>
                      )}
                      {item?.fields?.title === "Map Image" && (
                        <div className={`w-100`}>
                          <a
                            href={item?.fields?.description}
                            target={"_blank"}
                            rel="noreferrer"
                          >
                            <Image
                              imageUrl={item?.fields?.file?.url}
                              altText={item?.fields?.title}
                              className={`${
                                item?.fields?.caption ? "mb-16" : ""
                              }`}
                            />
                          </a>
                        </div>
                      )}
                    </>
                  )}
                  {item?.fields?.file?.contentType.includes("video") && (
                    <div className={`w-100 md:w-75 xl:w-60`}>
                      <Video
                        videoUrl={item?.fields?.file?.url}
                        classes={`${item?.fields?.caption ? "mb-16" : ""}`}
                        caption={item?.fields?.captions}
                      />
                    </div>
                  )}
                </div>
              ))}
              {content?.video && (
                <div className={`w-100 md:w-75 xl:w-60`}>
                  <Video
                    videoUrl={content?.video}
                    classes={`${content?.caption ? "mb-16" : ""} mt-16`}
                    caption={content?.captions}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      ) : (
        <PageNotFound content={content} />
      )}
    </>
  );
};

export default TextBlock;
